import axios from "axios";
import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useForm, NestedValue } from "react-hook-form";
import {
  Supplier_GetSupplierById,
  Supplier_GetSupplierByName,
  Supplier_GetSupplierByNameByDirection,
  SupplierItem_GetList,
} from "../../../ApiEndpoints";
import ApproveSupplierItemList from "./ApproveSupplierItemList";

export interface SupplierData {
  supplierNumber: string;
  supplierName2: string;
  supplierName1: string;
  supplierStreet: string;
  supplierStreetNumber: string;
  supplierPostalCode: string;
  supplierCity: string;
  supplierCountry: string;
  supplierVAT: string;
  supplierLocationInfo: Array<SupplierLocationInfo>;
}

export interface SupplierLocationInfo {
  supplierLocationId: string;
  supplierLocationNumber: string;
  supplierLocationDescription: string;
}

export interface ItemData {
  itemNumber: string;
  description: string;
}

export interface SupplierItem {
  supplier: Supplier;
  items: Item[];
}

export interface Item {
  id: string;
  itemNumber: string;
  itemCode: string;
  buySell: string;
  description: string;
  descriptionDutch: string;
  descriptionFrench: string;
  descriptionEnglish: string;
  descriptionGerman: string;
  euralCode: string;
  rdCode: string;
  processingType: string;
  processingTypeDescription: string;
}

export interface Supplier {
  supplierNumber: string;
  supplierName1: string;
  supplierName2: string;
  supplierStreet: string;
  supplierStreetNumber: string;
  supplierPostalCode: string;
  supplierCity: string;
  supplierCountry: string;
  supplierVAT: string;
  supplierType: number;
  originType: string;
  destinationType: string;
  supplierLocationInfo: any[];
}

const ApproveSupplierItem: React.FC = () => {
  const [supplierData, setSupplierData] = useState<SupplierData>({
    supplierNumber: "",
    supplierName2: "",
    supplierName1: "",
    supplierStreet: "",
    supplierStreetNumber: "",
    supplierPostalCode: "",
    supplierCity: "",
    supplierCountry: "",
    supplierVAT: "",
    supplierLocationInfo: [],
  });
  const [supplierItem, setSupplierItem] = useState<SupplierItem>({
    supplier: {
      supplierNumber: "",
      supplierName1: "",
      supplierName2: "",
      supplierStreet: "",
      supplierStreetNumber: "",
      supplierPostalCode: "",
      supplierCity: "",
      supplierCountry: "",
      supplierVAT: "",
      supplierType: 0,
      originType: "",
      destinationType: "",
      supplierLocationInfo: [],
    },
    items: [],
  });

  const [selectedSupplierLocationNumber, setSelectedSupplierLocationNumber] =
    useState<string>("");

  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });
  const [isSupplierSelected, setIsSupplierSelected] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    axios
      .get<SupplierItem>(SupplierItem_GetList, {
        params: {
          SupplierId: parseInt(data.SupplierNr),
        },
      })
      .then((response) => {
        setSupplierItem(response.data);
        setIsSupplierSelected(true);
      })
      .catch((error) => {});
  };

  const onSupplierNumberChange = async (supplierNr: string) => {
    let supplierDataLocal = supplierData;
    supplierDataLocal.supplierNumber = supplierNr;
    setSupplierData(supplierDataLocal);
    await axios
      .get<SupplierData>(Supplier_GetSupplierById, {
        params: {
          SupplierNumber: supplierNr,
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: supplierData.supplierNumber,
          supplierName2: "",
          supplierName1: "",
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onSupplierNameChange = async (supplierName: string) => {
    await axios
      .get<SupplierData>(Supplier_GetSupplierByName, {
        params: {
          SupplierName: supplierName.trim(),
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
        document.getElementById("SupplierName")!.focus();
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: "",
          supplierName2: "",
          supplierName1: supplierData.supplierName1,
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onSupplierNumberIncrease = () => {
    onSupplierNumberChange(
      (Number(supplierData.supplierNumber) + 1).toString()
    );
  };

  const onSupplierNumberDecrease = () => {
    if (
      supplierData.supplierNumber === "0" ||
      supplierData.supplierNumber === ""
    ) {
      return;
    }
    onSupplierNumberChange(
      (Number(supplierData.supplierNumber) - 1).toString()
    );
  };

  const onSupplierNameNext = async () => {
    await axios
      .get<SupplierData>(Supplier_GetSupplierByNameByDirection, {
        params: {
          SupplierName: supplierData.supplierName1,
          Direction: "Asc",
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: "",
          supplierName2: "",
          supplierName1: supplierData.supplierName1,
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onSupplierNamePrevious = async () => {
    await axios
      .get<SupplierData>(Supplier_GetSupplierByNameByDirection, {
        params: {
          SupplierName: supplierData.supplierName1,
          Direction: "Desc",
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: "",
          supplierName2: "",
          supplierName1: supplierData.supplierName1,
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onSupplierLocationInfoChange = (e: any) => {
    const supplierLocationInfo = supplierData.supplierLocationInfo.find(
      (obj) => {
        return obj.supplierLocationId === e.target.value;
      }
    );
    setSelectedSupplierLocationNumber(
      supplierLocationInfo!.supplierLocationNumber
    );
  };

  const initialiseSupplierLocationNumber = (response: any) => {
    setSelectedSupplierLocationNumber("");
    if (response.data.supplierLocationInfo.length > 0) {
      setSelectedSupplierLocationNumber(
        response.data.supplierLocationInfo[0].supplierLocationNumber
      );
    }
  };

  const resetState = () => {
    setIsSupplierSelected(!isSupplierSelected);
  };

  return isSupplierSelected ? (
    <ApproveSupplierItemList
      supplierItem={supplierItem}
      isSupplierSelected={resetState}
    />
  ) : (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-8 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-1 md:mt-0 md:col-span-6">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-4 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-x-6">
                    <div className="col-span-4 sm:col-span-4" />
                  </div>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="SupplierNr"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Leveranciersnummer
                      </label>
                      <DebounceInput
                        minLength={1}
                        debounceTimeout={300}
                        onChange={(event) =>
                          onSupplierNumberChange(event.target.value)
                        }
                        type="number"
                        name="SupplierNr"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        value={supplierData.supplierNumber}
                        autoComplete="new-password"
                        required
                      />
                      <input
                        type="number"
                        name="SupplierNr"
                        hidden
                        value={supplierData.supplierNumber}
                        ref={register}
                        required
                        readOnly
                      />
                      <i
                        className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                        onClick={() => onSupplierNumberDecrease()}
                      ></i>
                      <i
                        className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                        onClick={() => onSupplierNumberIncrease()}
                      ></i>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="SupplierName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Naam
                      </label>
                      <div className="grid grid-cols-5 gap-x-6">
                        <div className="col-span-4 sm:col-span-5">
                          <DebounceInput
                            key={supplierData.supplierName1}
                            minLength={1}
                            debounceTimeout={1500}
                            onChange={(event) =>
                              onSupplierNameChange(event.target.value)
                            }
                            type="text"
                            name="SupplierName"
                            id="SupplierName"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                            ref={register}
                            value={supplierData.supplierName1}
                            autoComplete="new-password"
                            required
                          />
                          <input
                            type="text"
                            name="SupplierName"
                            hidden
                            value={supplierData.supplierName1}
                            ref={register}
                            readOnly
                            autoComplete="new-password"
                            required
                          />
                          <i
                            className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                            onClick={() => onSupplierNamePrevious()}
                          />
                          <i
                            className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                            onClick={() => onSupplierNameNext()}
                          />
                        </div>
                        <input
                          type="text"
                          name="Supplierstreet"
                          id="SupplierName"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                          value={
                            supplierData.supplierStreet +
                            " " +
                            supplierData.supplierStreetNumber
                          }
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <input
                          type="text"
                          name="SupplierPostalCode"
                          id="SupplierName"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2"
                          value={supplierData.supplierPostalCode}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <input
                          type="text"
                          name="SupplierRegion"
                          id="SupplierName"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-3"
                          value={supplierData.supplierCity}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <div
                          className="hidden sm:block col-span-6 sm:col-span-6 mt-2"
                          aria-hidden="true"
                        >
                          <div>
                            <div className="border-t border-gray-200" />
                          </div>
                        </div>
                        <input
                          type="text"
                          name="SupplierLocationNumber"
                          id="SupplierLocationNumber"
                          className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2"
                          value={selectedSupplierLocationNumber}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <select
                          name="SupplierLocationDescription"
                          id="SupplierLocationDescription"
                          className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-3"
                          onChange={(e) => onSupplierLocationInfoChange(e)}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                        >
                          {supplierData.supplierLocationInfo.map(
                            (location, index) => (
                              <option
                                key={index}
                                value={location.supplierLocationId}
                              >
                                {location.supplierLocationDescription}
                              </option>
                            )
                          )}
                        </select>
                        <input
                          type="text"
                          name="supplierVAT"
                          id="supplierVAT"
                          className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2"
                          value={supplierData.supplierVAT}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-5 sm:col-span-5" />
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <input
                    type="submit"
                    value="Zoeken"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default ApproveSupplierItem;
