import axios from "axios";
import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useForm, NestedValue } from "react-hook-form";
import {
  Item_GetItemById,
  Item_GetItemByIdByDirection,
  SupplierItem_baseUrl,
  SupplierItem_GetList,
} from "../../../ApiEndpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export interface SupplierData {
  supplierNumber: string;
  supplierName2: string;
  supplierName1: string;
  supplierStreet: string;
  supplierStreetNumber: string;
  supplierPostalCode: string;
  supplierCity: string;
  supplierCountry: string;
  supplierVAT: string;
  supplierLocationInfo: Array<SupplierLocationInfo>;
}

export interface SupplierLocationInfo {
  supplierLocationId: string;
  supplierLocationNumber: string;
  supplierLocationDescription: string;
}

export interface ItemData {
  id: string;
  itemNumber: string;
  description: string;
}
export interface SupplierItemProps {
  supplierItem: SupplierItem;
  isSupplierSelected: () => void;
}
export interface SupplierItem {
  supplier: Supplier;
  items: Item[];
}

export interface Item {
  id: string;
  itemNumber: string;
  itemCode: string;
  buySell: string;
  description: string;
  descriptionDutch: string;
  descriptionFrench: string;
  descriptionEnglish: string;
  descriptionGerman: string;
  euralCode: string;
  rdCode: string;
  processingType: string;
  processingTypeDescription: string;
}

export interface Supplier {
  supplierNumber: string;
  supplierName1: string;
  supplierName2: string;
  supplierStreet: string;
  supplierStreetNumber: string;
  supplierPostalCode: string;
  supplierCity: string;
  supplierCountry: string;
  supplierVAT: string;
  supplierType: number;
  originType: string;
  destinationType: string;
  supplierLocationInfo: any[];
}

const ApproveSupplierItemList: React.FC<SupplierItemProps> = (supplierItem) => {
  const [itemData, setItemData] = useState<ItemData>({
    id: "",
    itemNumber: "",
    description: "",
  });
  const [supplierItemState, setSupplierItemState] = useState<SupplierItem>();

  useEffect(() => {
    setSupplierItemState(supplierItem.supplierItem);
  }, []);

  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });

  const onSubmit = (data: any) => {
    axios
      .post(SupplierItem_baseUrl, {
        supplierId: supplierItemState?.supplier.supplierNumber,
        itemId: itemData.itemNumber,
      })
      .then((response) => {
        onRefreshData();
      })
      .catch((error) => {});
  };

  const onMaterialIdChange = async (ItemNumber: string) => {
    await axios
      .get<ItemData>(Item_GetItemById, {
        params: {
          ItemNumber: ItemNumber,
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          id: itemData.id,
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onItemNumberNext = async () => {
    await axios
      .get<ItemData>(Item_GetItemByIdByDirection, {
        params: {
          ItemId: itemData.itemNumber,
          Direction: "Asc",
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          id: itemData.id,
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onItemNumberPrevious = async () => {
    await axios
      .get<ItemData>(Item_GetItemByIdByDirection, {
        params: {
          ItemId: itemData.itemNumber,
          Direction: "Desc",
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          id: itemData.id,
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onPressDelete = async (supplierId: string, itemNumber: string) => {
    axios
      .delete(SupplierItem_baseUrl, {
        data: {
          supplierId: supplierId,
          itemId: itemNumber,
        },
      })
      .then((response) => {
        onRefreshData();
      })
      .catch((error) => {});
  };

  const onRefreshData = async () => {
    axios
      .get<SupplierItem>(SupplierItem_GetList, {
        params: {
          SupplierId: parseInt(supplierItemState?.supplier.supplierNumber!),
        },
      })
      .then((response) => {
        setSupplierItemState(response.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-12 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-10">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
              <div className="shadow overflow-hidden sm:rounded-md flex">
                <div className="px-4 py-5 bg-white sm:p-6 w-2/5">
                  <div className="col-span-6 sm:col-span-6">
                    <h3 className="pb-4">
                      {supplierItemState?.supplier.supplierName1}
                    </h3>
                    <label
                      htmlFor="MaterialId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Artikel nummer
                    </label>
                    <DebounceInput
                      minLength={4}
                      debounceTimeout={250}
                      onChange={(event) =>
                        onMaterialIdChange(event.target.value)
                      }
                      type="text"
                      name="MaterialId"
                      id="MaterialId"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5 dense"
                      ref={register}
                      value={itemData.itemNumber}
                      required
                      autoComplete="nope"
                    />
                    <input
                      type="text"
                      name="MaterialId"
                      hidden
                      value={itemData.itemNumber}
                      ref={register}
                      readOnly
                      required
                      autoComplete="nope"
                    />
                    <i
                      className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                      onClick={() => onItemNumberPrevious()}
                    ></i>
                    <i
                      className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                      onClick={() => onItemNumberNext()}
                    ></i>
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="Material"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Artikel
                    </label>
                    <input
                      type="text"
                      name="Material"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      value={itemData.description}
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                      readOnly
                    />
                  </div>
                </div>
                <div className="px-4 py-5 bg-white sm:p-6 w-3/5">
                  <h3 className="pb-4"></h3>

                  <table className="table-fixed text-left">
                    <thead className="flex w-full">
                      <tr className="flex w-full">
                        <th className="w-1/12 px-4 py-2"></th>
                        <th className="w-3/12 px-4 py-2">Artikel nummer</th>
                        <th className="w-6/12 px-4 py-2">
                          Artikel Omschrijving
                        </th>
                        <th className="w-2/12 px-4 py-2">Eural code</th>
                      </tr>
                    </thead>
                    <tbody
                      className="bg-grey-light flex flex-col items-center overflow-y-scroll w-full"
                      style={{ height: "50vh" }}
                    >
                      {supplierItemState?.items.map((item) => (
                        <tr className="flex w-full" key={item.id}>
                          <td className="px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                onPressDelete(
                                  supplierItemState.supplier.supplierNumber,
                                  item.itemNumber
                                );
                              }}
                            />
                          </td>
                          <td className="border-r-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-3/12">
                            {item.itemNumber}
                          </td>
                          <td className=" px-4 py-2 text-light-blue-600 font-medium w-6/12">
                            {item.description}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-2/12">
                            {item.euralCode}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <span
                  onClick={() => supplierItem.isSupplierSelected()}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 mr-2"
                  style={{ cursor: "pointer" }}
                >
                  Terug
                </span>
                <input
                  type="submit"
                  value="Toevoegen"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                />
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default ApproveSupplierItemList;
