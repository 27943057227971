import axios from "axios";
import React, {useState, useEffect} from "react";
import {DebounceInput} from 'react-debounce-input';
import { useForm, NestedValue } from 'react-hook-form';
import CustomTable from '../../../components/Table/table'
import _ from 'lodash';
import { store } from 'react-notifications-component';
import  Modal  from "../../../components/Modal/modal";
import { Weight_WeightNotes_Invoice,
    Weight_AddInvoiceToWeightNote,
    Supplier_GetSupplierById,
    Supplier_GetSupplierByName,
    Supplier_GetSupplierByNameByDirection } from "../../../ApiEndpoints"

const styles = require("./styles.css");

export interface SupplierData{
  supplierNumber :string
  supplierName2 :string
  supplierName1:string
  supplierStreet :string
  supplierStreetNumber :string
  supplierPostalCode :string
  supplierCity :string
  supplierCountry :string
}

export interface WeightNoteArray{
 weightNotes: Array<WeightNotes>
}

export interface WeightNotes{
  id: string
  supplierNumber :string
  date :string
  weightNoteNumber :string
  transporter :string
  itemNumber :string
  weight :number
  description :string
  bookedIn :boolean
  invoiceNumber :string
  invoicePeriod :string
  price :string
}

export interface TemporaryInvoiceData{
  invoiceNumber :string
  invoicePeriod :string
}

const GewichtsNota: React.FC = () => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showWeightNoteList, setShowWeightNoteList] = useState<boolean>(false)
  const [totalWeight, setTotalWeight] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [displayModal, setDisplayModal] = useState<boolean>(false)
  const [temporaryInvoiceData, setTemporaryInvoiceData] = useState<TemporaryInvoiceData>({
    invoiceNumber :"",
    invoicePeriod :""
  })
  const [supplierData, setSupplierData] = useState<SupplierData>({
    supplierNumber :"",
    supplierName2:"",
    supplierName1:"",
    supplierStreet :"",
    supplierStreetNumber :"",
    supplierPostalCode :"",
    supplierCity :"",
    supplierCountry :""
  })
  const [weightNoteList, setWeightNoteList] = useState<Array<WeightNotes>>([{
    id:"",
    supplierNumber :"",
    date :"",
    weightNoteNumber :"",
    transporter :"",
    itemNumber :"",
    weight :0,
    description : "",
    bookedIn :false,
    invoiceNumber :"",
    invoicePeriod :"",
    price :""
  }])
  const [ChangedWeightNoteList, setChangedWeightNoteList] = useState<Array<WeightNotes>>([{
    id:"",
    supplierNumber :"",
    date :"",
    weightNoteNumber :"",
    transporter :"",
    itemNumber :"",
    weight :0,
    description : "",
    bookedIn :false,
    invoiceNumber :"",
    invoicePeriod :"",
    price :""
  }])
  const [Columns] = useState([
    { name: 'price', title: 'Euro' },
    { name: 'date', title: 'Lev Dat' },
    { name: 'weightNoteNumber', title: 'Bonnr' },
    { name: 'weight', title: 'Gewicht' },
    { name: 'itemDescription', title: 'Omschrijving' },
    { name: 'itemNumber', title: 'Artnr' },
    { name: 'transporter', title: 'Transporteur' },
    { name: 'description', title: 'Opmerking' },
    { name: 'invoiceNumber', title: 'Factuurnr' },
    { name: 'bookedIn', title: 'Ingeboekt' },
  ])
  const [columnExtension]= useState([
    {columnName: 'price', width:"50"},
    {columnName: 'date', width:"90"},
    {columnName: 'weightNoteNumber', width:"80"},
    {columnName: 'weight', width:"75"},
    {columnName: 'itemDescription'},
    {columnName: 'itemNumber', width:"75"},
    {columnName: 'transporter', width:"90"},
    {columnName: 'description'},
    {columnName: 'invoiceNumber', width:"90"},
    {columnName: 'bookedIn', width:"1"},
  ])
  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });

  useEffect(() => {
    document.getElementById("SupplierNr")!.focus();
  },[]);

  const onSubmit = (data: any) => {
    setLoadingData(true)
    let notifID = store.addNotification({
      title: "Loading",
      message: "Gewichtslijst aan het ophalen...",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      onRemoval: (id, removedBy) => {
        setLoadingData(false)
      }
    });

    axios.get<WeightNoteArray>(Weight_WeightNotes_Invoice,
    {
      params: {
        SupplierNumber:data.SupplierNr,
        FromDateTime:data.From? data.From : null,
        ToDateTime:data.To? data.To : null
      }
    }).then((response) =>{
        store.removeNotification(notifID)
        setLoadingData(false)
        setWeightNoteList(response.data.weightNotes)

        setShowWeightNoteList(true)

        document.getElementById("Invoice")!.focus();
      })
      .catch((error)=>{
        store.removeNotification(notifID)
        setLoadingData(false)
      })
  };

  const ToggleModal =()=>{
    setDisplayModal(!displayModal)
  }

  const validateWeightNote = (data :any) =>{
    if (displayModal === false) {
      setTemporaryInvoiceData({
        invoiceNumber :data.Invoice,
        invoicePeriod :data.Period
      });
      ToggleModal()
    }
    else{

      let difference = _.differenceWith(ChangedWeightNoteList, weightNoteList, _.isEqual);
      
      axios.post(Weight_AddInvoiceToWeightNote,
      {
        invoiceNumber: temporaryInvoiceData.invoiceNumber,
        invoicePeriod: temporaryInvoiceData.invoicePeriod,
        weightNotes: difference
      }).then((response) =>{
        setInvoiceNumber((Number(temporaryInvoiceData.invoiceNumber)+1).toString());
        resetState();
      })
      .catch((error)=>{
        setDisplayModal(false)
      })
    }
  }

  const onSupplierNumberChange = async(supplierNr:string)=>{
    await axios.get<SupplierData>(Supplier_GetSupplierById,
    {
      params: {
        SupplierNumber: supplierNr
      }
    })
    .then((response)=>{
      setSupplierData(response.data)
    }).catch((error)=>{
      setSupplierData({
        supplierNumber :supplierData.supplierNumber,
        supplierName2:"",
        supplierName1:"",
        supplierStreet :"",
        supplierStreetNumber :"",
        supplierPostalCode :"",
        supplierCity :"",
        supplierCountry :""
      })
    })
  }

  const onSupplierNameChange = async(supplierName:string)=>{
    await axios.get<SupplierData>(Supplier_GetSupplierByName,
    {
      params: {
        SupplierName: supplierName.trim()
      }
    })
    .then((response)=>{
      setSupplierData(response.data)
    }).catch((error)=>{
      setSupplierData({
        supplierNumber :"",
        supplierName2:"",
        supplierName1:supplierData.supplierName1,
        supplierStreet :"",
        supplierStreetNumber :"",
        supplierPostalCode :"",
        supplierCity :"",
        supplierCountry :""
      })
    })
  }

  const onSupplierNumberIncrease =()=>{
    onSupplierNumberChange((Number(supplierData.supplierNumber)+1).toString())
  }

  const onSupplierNumberDecrease = () =>{
    if (supplierData.supplierNumber === "0" || supplierData.supplierNumber === "") {
      return      
    }
    onSupplierNumberChange((Number(supplierData.supplierNumber)-1).toString())
  }

  const onSupplierNameNext = async () =>{
    await axios.get<SupplierData>(Supplier_GetSupplierByNameByDirection,
    {
      params: {
        SupplierName: supplierData.supplierName1,
        Direction:"Asc"
      }
    })
    .then((response)=>{
      setSupplierData(response.data)
    }).catch((error)=>{
      setSupplierData({
        supplierNumber :"",
        supplierName2:"",
        supplierName1:supplierData.supplierName1,
        supplierStreet :"",
        supplierStreetNumber :"",
        supplierPostalCode :"",
        supplierCity :"",
        supplierCountry :""
      })
    })
  }

  const onSupplierNamePrevious = async () =>{
    await axios.get<SupplierData>(Supplier_GetSupplierByNameByDirection,
    {
      params: {
        SupplierName: supplierData.supplierName1,
        Direction:"Desc"
      }
    })
    .then((response)=>{
      setSupplierData(response.data)
    }).catch((error)=>{
      setSupplierData({
        supplierNumber :"",
        supplierName2:"",
        supplierName1:supplierData.supplierName1,
        supplierStreet :"",
        supplierStreetNumber :"",
        supplierPostalCode :"",
        supplierCity :"",
        supplierCountry :""
      })
    })
  }

  const onWeightNoteChange =(ChangedArray:Array<WeightNotes>) =>{
    setChangedWeightNoteList(ChangedArray)

    let difference = _.differenceWith(ChangedArray, weightNoteList, _.isEqual);

    let weightTotal:number = 0;
    let amountTotal:number = 0;

    difference.forEach(weightNote =>{
      if (weightNote.price !== "" && weightNote.price !== null) {
        weightTotal = weightTotal + weightNote.weight
      amountTotal = amountTotal + (Number(weightNote.price) * weightNote.weight)
      }
    })

    setTotalWeight(weightTotal)
    setTotalAmount(amountTotal)
  }

  const resetState =()=>{
    setTotalWeight(0)
    setTotalAmount(0)
    setSupplierData({
      supplierNumber :"",
      supplierName2:"",
      supplierName1:"",
      supplierStreet :"",
      supplierStreetNumber :"",
      supplierPostalCode :"",
      supplierCity :"",
      supplierCountry :""
    })
    setWeightNoteList([{
      id:"",
      supplierNumber :"",
      date :"",
      weightNoteNumber :"",
      transporter :"",
      itemNumber :"",
      weight :0,
      description : "",
      bookedIn :false,
      invoiceNumber :"",
      invoicePeriod :"",
      price :""
    }])
    setChangedWeightNoteList([{
      id:"",
      supplierNumber :"",
      date :"",
      weightNoteNumber :"",
      transporter :"",
      itemNumber :"",
      weight :0,
      description : "",
      bookedIn :false,
      invoiceNumber :"",
      invoicePeriod :"",
      price :""
    }])
    setShowWeightNoteList(false)
    setDisplayModal(false)
    document.getElementById("SupplierNr")!.focus();
  }
  
  return (
    <>
  <div className="mt-10 sm:mt-0">
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-1"/>
      <div className="mt-1 md:mt-0 col-span-10">
        {showWeightNoteList ?

        <form onSubmit={handleSubmit(validateWeightNote)} autoComplete="nope">
        <Modal isOn={displayModal} onCancel={() => ToggleModal()} onSubmit={() => validateWeightNote("x")} title={"Factuur nummer controleren"}>
          <p>Is <strong>{temporaryInvoiceData.invoiceNumber}</strong> het gewenste factuurnummer?</p>
        </Modal> 

          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-4 bg-white sm:p-6">
              
              <div className="grid grid-cols-6 gap-6">  
                <div className="col-span-1 sm:col-span-2">
                  <label htmlFor="leveranciersnummer" className="block text-sm font-medium text-gray-700">Leverancier</label>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 sm:col-span-2">
                      <input type="number" name="leveranciersnummer" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} value={supplierData.supplierNumber} disabled required/>
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                      <input type="text" name="leveranciersnaam" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} value={supplierData.supplierName1} disabled required/>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 sm:col-span-2">
                  <label htmlFor="Invoice" className="block text-sm font-medium text-gray-700">factuur nummer</label>
                  <input type="text" name="Invoice" id="Invoice" key={invoiceNumber} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} defaultValue={invoiceNumber} onKeyPress={(e:React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => { e.key === 'Enter' && e.preventDefault(); }} required/>
                </div>
                <div className="col-span-1 sm:col-span-2">
                  <label htmlFor="Period" className="block text-sm font-medium text-gray-700">Begin Periode</label>
                  <input type="text" name="Period" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register}/>
                </div>
              </div>
              <br/>
              <div >
                <CustomTable data={weightNoteList} Columns={Columns} key={weightNoteList} onWeightNoteChange={onWeightNoteChange} columnExtension={columnExtension} />
              </div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-1 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">{totalWeight} kg</label>
                <label className="block text-sm font-medium text-gray-700">{totalAmount} EUR</label>
                </div>
              </div>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
              <div className="px-4 py-3 text-right sm:px-6 ">
                <span onClick={() => resetState()} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{cursor:"pointer"}}>
                  Terug
                </span>
              </div>
              <div className="px-4 py-3 text-right sm:px-6 ">
                <input type="submit" value="Opslaan" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"/>
              </div>
            </div>
            </div>
          </div>
        </form>
        :
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-4 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                  
                  <label htmlFor="SupplierNr" className="block text-sm font-medium text-gray-700">Leveranciersnummer</label>
                  <DebounceInput minLength={1}
                    debounceTimeout={250}
                    onChange={event => (onSupplierNumberChange(event.target.value))}
                    type="number" name="SupplierNr" id="SupplierNr" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} value={supplierData.supplierNumber} required autoComplete="nope"/>
                  <input type="number" name="SupplierNr" hidden value={supplierData.supplierNumber} ref={register} required readOnly autoComplete="nope"/>
                  <i className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300" onClick={()=> onSupplierNumberDecrease()}></i>
                  <i className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300" onClick={()=> onSupplierNumberIncrease()}></i>
                </div>
  
                <div className="col-span-3 sm:col-span-3">
                  <label htmlFor="SupplierName" className="block text-sm font-medium text-gray-700">Naam</label>
                  <div className="grid grid-cols-5 gap-x-6">
                    <div className="col-span-4 sm:col-span-5">
                      <DebounceInput minLength={1}
                      debounceTimeout={1000}
                      onChange={event => (onSupplierNameChange(event.target.value))}
                      type="text" name="SupplierName" id="SupplierName" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5" ref={register} value={supplierData.supplierName1} required autoComplete="nope" />
                      <input type="text" name="SupplierName" hidden value={supplierData.supplierName1} ref={register} readOnly required autoComplete="nope"/>
                      <i className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300" onClick={()=> onSupplierNamePrevious()}/>
                      <i className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300" onClick={()=> onSupplierNameNext()}/>
                    </div>
                    <input type="text" name="Supplierstreet" id="SupplierName" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5" value={supplierData.supplierStreet + " " + supplierData.supplierStreetNumber} ref={register} autoComplete="nope" tabIndex={-1} readOnly/>
                    <input type="text" name="SupplierPostalCode" id="SupplierName" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2" value={supplierData.supplierPostalCode} ref={register}  autoComplete="nope" tabIndex={-1} readOnly/>
                    <input type="text" name="SupplierRegion" id="SupplierName" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-3" value={supplierData.supplierCity} ref={register} autoComplete="nope" tabIndex={-1} readOnly/>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-5 sm:col-span-5"/>
                  <div className="hidden sm:block col-span-6 sm:col-span-6" aria-hidden="true">
                    <div >
                      <div className="border-t border-gray-200"></div>
                    </div>
                  </div>
                
                  <div className="col-span-1 sm:col-span-2">
                    <label htmlFor="From" className="block text-sm font-medium text-gray-700">Begin Periode</label>
                    <input type="Date" name="From" id="From" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} defaultValue={new Date(new Date().setFullYear(new Date().getFullYear()-1)).toISOString().substr(0,10)}/>
                  </div>
    
                  <div className="col-span-1 sm:col-span-2">
                    <label htmlFor="To" className="block text-sm font-medium text-gray-700">Eind Periode</label>
                    <input type="Date" name="To"  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} defaultValue={new Date().toISOString().substr(0,10)}/>
                  </div>

                  <div className="col-span-4 sm:col-span-4"/>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 ">
              <input type="submit" value="Ophalen" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" disabled={loadingData}/>
            </div>
          </div>
        </form>
        }
      </div>
      <div className="col-span-1"/>
    </div>
  </div>
</>

  );
};

export default GewichtsNota;