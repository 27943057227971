import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect, useHistory  } from "react-router-dom";
import { RouteComponentProps } from "react-router";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import { AppStore } from "../Store/AppState";
import routes from "../routes.js";
import {parseJwt} from "../Utils/UtilFunctions.js";


const Admin: React.FC<RouteComponentProps>= () => {
  const jwtToken = AppStore.useState(s => s.jwtToken);
  const history: any = useHistory();

  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef<any | null>(null);
  
    React.useEffect(() => {
      if (jwtToken === "" || jwtToken === undefined) {
    
      }
      else {
        document.documentElement.scrollTop = 0;
        document.scrollingElement!.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
      }
    }, [location, jwtToken]);

    const sidebarRoutes = (routes:any)=>{
      let routesToRender=[];
      for (let route of routes) {
        if (route.showInSidebar === true) {
          routesToRender.push(route);
        }
      } 
      return routesToRender;
    }
  
  const getRoutes = (routes:any) => {
    return routes.map((prop:any, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e:any) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
      return "light"
  };

  const checkIfJwtInLocalStorage = () => {
    const jwt = localStorage.getItem("jwt");
    let jwtJson:any;
    if(jwt){
      jwtJson = parseJwt(jwt);

      AppStore.update(s => {
        s.jwtToken = jwt;
        s.jwtExpiration = jwtJson.exp;
        s.jwtEmail = jwtJson["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        s.jwtName = jwtJson["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        s.jwtRole = jwtJson["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      })

      if((jwtJson === "" || jwtJson === undefined ) && ( jwtJson.exp === "" || Date.now() >= new Date(jwtJson.exp).getTime() ))
        history.push("/auth/login")

    }
    if((jwt === "" || jwt === undefined || !jwt ))
        history.push("/auth/login")
  }
  //Execute functions
  checkIfJwtInLocalStorage()


  return (
    <>
    <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/Delorge.png").default,
          imgAlt: "...",
          style:{minHeight:44, maxHeight:44}
        }}
        style={{zIndex:99}}
        />
      <Sidebar
        routes={sidebarRoutes(routes)}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        style={{zIndex:98}}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/Delorge.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/" />
        </Switch>
        <AdminFooter />
      </div>
      {/* {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null} */}
    </>
  );
}

export default Admin;
