import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
// plugins styles from node_modules
// import "react-notification-alert/dist/animate.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import "@fullcalendar/common/main.min.css";
// import "@fullcalendar/daygrid/main.min.css";
// import "sweetalert2/dist/sweetalert2.min.css";
// import "select2/dist/css/select2.min.css";
// import "quill/dist/quill.core.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import AdminLayout from "./layouts/Admin";
import Auth from "./layouts/Auth.jsx";
import {JwtInterceptor} from "./Utils/jwtInterceptor";
import {errorInterceptor} from "./Utils/errorInterceptor";
import Authverify from './Utils/Authverify';
require('dotenv').config();

// enable interceptors for http requests
JwtInterceptor();
errorInterceptor();

ReactDOM.render(<div>
  <ReactNotification />
  <BrowserRouter>
    <Switch>
      <Route path="/auth" render={props => <Auth {...props} />} />
      <Route path="/" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
    <Authverify/>
  </BrowserRouter>
</div> ,
document.getElementById("root")
);

