import axios from "axios";
import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useForm, NestedValue } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal/modal";
import {
  Item_GetItemById,
  Item_GetItemByIdByDirection,
  SupplierItemPrice_BaseUrl,
  SupplierItemPrice_GetBySupplierId,
  SupplierItemPrice_GetById,
} from "../../../ApiEndpoints";

export interface Supplier {
  supplierNumber: string;
  supplierName1: string;
  supplierName2: string;
  supplierStreet: string;
  supplierStreetNumber: string;
  supplierPostalCode: string;
  supplierCity: string;
  supplierCountry: string;
  supplierVAT: string;
  supplierType: number;
  originType: string;
  destinationType: string;
  supplierLocationInfo: Array<SupplierLocationInfo>;
}

export interface SupplierLocationInfo {
  supplierLocationId: string;
  supplierLocationNumber: string;
  supplierLocationDescription: string;
}

export interface ItemData {
  id: string;
  itemNumber: string;
  description: string;
}
export interface SupplierProps {
  supplier: Supplier;
  isSupplierSelected: () => void;
}

export interface SupplierItemPrice {
  id: string;
  supplierId: string;
  itemId: string;
  itemNumber: string;
  itemDescription: string;
  price: number;
  beginDate: Date;
  endDate: Date;
  isPrijsPerAfhaling: boolean;
  isGeleverd: boolean;
  transportkost: number;
}

const SupplierItemPriceList: React.FC<SupplierProps> = (supplier) => {
  const [supplierState, setSupplierState] = useState<Supplier>({
    supplierNumber: "",
    supplierName2: "",
    supplierName1: "",
    supplierStreet: "",
    supplierStreetNumber: "",
    supplierPostalCode: "",
    supplierCity: "",
    supplierCountry: "",
    supplierVAT: "",
    destinationType: "",
    originType: "",
    supplierType: 0,
    supplierLocationInfo: [],
  });
  const [itemData, setItemData] = useState<ItemData>({
    id: "",
    itemNumber: "",
    description: "",
  });
  const [supplierItemPriceState, setSupplierItemPriceState] =
    useState<SupplierItemPrice[]>();
  const [IsEditOn, setIsEditOn] = useState<boolean>(false);
  const [IsDeleteOn, setIsDeleteOn] = useState<boolean>(false);
  const [DeletableId, setDeletableId] = useState<string>("");
  const [editableItem, setEditableItem] = useState<SupplierItemPrice>({
    id: "test",
    supplierId: "",
    itemId: "",
    itemNumber: "",
    itemDescription: "",
    price: 0,
    beginDate: new Date(),
    endDate: new Date(),
    isPrijsPerAfhaling: false,
    isGeleverd: false,
    transportkost: 0,
  });

  const shortDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  useEffect(() => {
    setSupplierState(supplier.supplier);
  }, []);

  useEffect(() => {
    onRefreshData();
  }, [supplierState]);

  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });

  const onSubmit = (data: any) => {
    axios
      .post(SupplierItemPrice_BaseUrl, {
        supplierId: supplierState?.supplierNumber,
        itemId: itemData.id,
        price: data.Price,
        BeginDate: data.StartDate,
        EndDate: data.EndDate,
        IsPrijsPerAfhaling: data.IsPrijsPerAfhaling,
        IsGeleverd: data.IsGeleverd,
        Transportkost: data.Transportkost,
      })
      .then((response) => {
        onRefreshData();
      })
      .catch((error) => {});
  };

  const onMaterialIdChange = async (ItemNumber: string) => {
    await axios
      .get<ItemData>(Item_GetItemById, {
        params: {
          ItemNumber: ItemNumber,
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          id: itemData.id,
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onItemNumberNext = async () => {
    await axios
      .get<ItemData>(Item_GetItemByIdByDirection, {
        params: {
          ItemId: itemData.itemNumber,
          Direction: "Asc",
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          id: itemData.id,
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onItemNumberPrevious = async () => {
    await axios
      .get<ItemData>(Item_GetItemByIdByDirection, {
        params: {
          ItemId: itemData.itemNumber,
          Direction: "Desc",
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          id: itemData.id,
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onRefreshData = async () => {
    if (supplierState?.supplierNumber !== "") {
      axios
        .get<SupplierItemPrice[]>(SupplierItemPrice_GetBySupplierId, {
          params: {
            SupplierId: parseInt(supplierState?.supplierNumber!),
          },
        })
        .then((response) => {
          setSupplierItemPriceState(response.data);
        });
    }
  };

  const onPressEdit = async (Id: string) => {
    axios
      .get<SupplierItemPrice>(SupplierItemPrice_GetById, {
        params: {
          id: Id,
        },
      })
      .then((response) => {
        setEditableItem(response.data);
        setIsEditOn(true);
      });
  };

  const onPressDelete = async (Id: string) => {
    setDeletableId(Id);
    setIsDeleteOn(true);
  };
  const onPressCancelEdit = () => {
    setIsEditOn(false);
  };
  const onPressSubmitEdit = () => {
    axios
      .put(SupplierItemPrice_BaseUrl, {
        Id: editableItem.id,
        Price: editableItem.price,
        BeginDate: editableItem.beginDate,
        EndDate: editableItem.endDate,
        IsPrijsPerAfhaling: editableItem.isPrijsPerAfhaling,
        IsGeleverd: editableItem.isGeleverd,
        Transportkost: editableItem.transportkost,
      })
      .then((response) => {
        setIsEditOn(false);
        onRefreshData();
      });
  };
  const onPressCancelDelete = () => {
    setIsDeleteOn(false);
  };
  const onPressSubmitDelete = () => {
    axios
      .delete(SupplierItemPrice_BaseUrl, {
        data: {
          Id: DeletableId,
        },
      })
      .then((response) => {
        setIsDeleteOn(false);
        onRefreshData();
      });
  };

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <Modal
          key={editableItem.id}
          isOn={IsEditOn}
          onCancel={onPressCancelEdit}
          onSubmit={onPressSubmitEdit}
          title={"Artikel Prijs aanpassen"}
        >
          <div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="ModalPrice"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prijs
                </label>
                <input
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                    setEditableItem({
                      ...editableItem,
                      price: Number(ev.target.value),
                    });
                  }}
                  type="number"
                  name="ModalPrice"
                  id="ModalPrice"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  autoComplete="nope"
                  defaultValue={editableItem.price}
                />
              </div>
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="ModalIsPrijsPerAfhaling"
                  className="block text-sm font-medium text-gray-700"
                >
                  Is prijs per afhaling?
                </label>
                <input
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                    setEditableItem({
                      ...editableItem,
                      isPrijsPerAfhaling: ev.target.value === "on",
                    });
                  }}
                  type="Checkbox"
                  name="ModalIsPrijsPerAfhaling"
                  id="ModalIsPrijsPerAfhaling"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  autoComplete="nope"
                  defaultChecked={editableItem.isPrijsPerAfhaling}
                />
              </div>
              <div className="col-span-4 sm:col-span-4" />
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="ModalBeginDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Begin datum
                </label>
                <input
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                    setEditableItem({
                      ...editableItem,
                      beginDate: new Date(ev.target.value),
                    })
                  }
                  type="Date"
                  name="ModalBeginDate"
                  id="ModalBeginDate"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                  autoComplete="nope"
                  defaultValue={new Date(editableItem.beginDate)
                    .toISOString()
                    .slice(0, 10)}
                />
              </div>
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="ModalEndDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Eind datum
                </label>
                <input
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                    setEditableItem({
                      ...editableItem,
                      endDate: new Date(ev.target.value),
                    })
                  }
                  type="Date"
                  name="ModalEndDate"
                  id="ModalEndDate"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                  autoComplete="nope"
                  defaultValue={new Date(editableItem.endDate)
                    .toISOString()
                    .slice(0, 10)}
                />
              </div>
              <div className="col-span-4 sm:col-span-4" />
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="ModalIsGeleverd"
                  className="block text-sm font-medium text-gray-700"
                >
                  Werd geleverd?
                </label>
                <input
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                    setEditableItem({
                      ...editableItem,
                      isGeleverd: ev.target.value === "on",
                    });
                  }}
                  type="Checkbox"
                  name="ModalIsGeleverd"
                  id="ModalIsGeleverd"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  autoComplete="nope"
                  defaultChecked={editableItem.isGeleverd}
                />
              </div>
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="ModalTransportkost"
                  className="block text-sm font-medium text-gray-700"
                >
                  Transportkost
                </label>
                <input
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                    setEditableItem({
                      ...editableItem,
                      transportkost: Number(ev.target.value),
                    });
                  }}
                  type="number"
                  name="ModalTransportkost"
                  id="ModalTransportkost"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  autoComplete="nope"
                  defaultValue={editableItem.transportkost}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          key={editableItem.id}
          isOn={IsDeleteOn}
          onCancel={onPressCancelDelete}
          onSubmit={onPressSubmitDelete}
          title={"Artikel Prijs verwijderen"}
        >
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-3 sm:col-span-3">
              <span className="block text-sm font-medium text-gray-700">
                Bent u zeker?
              </span>
            </div>
          </div>
        </Modal>
        <div className="md:grid md:grid-cols-12 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-10">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
              <div className="shadow overflow-hidden sm:rounded-md flex">
                <div className="px-4 py-5 bg-white sm:p-6 w-2/12">
                  <div className="col-span-6 sm:col-span-6">
                    <h3 className="pb-4">{supplierState?.supplierName1}</h3>
                    <label
                      htmlFor="MaterialId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Artikel nummer
                    </label>
                    <DebounceInput
                      minLength={4}
                      debounceTimeout={250}
                      onChange={(event) =>
                        onMaterialIdChange(event.target.value)
                      }
                      type="text"
                      name="MaterialId"
                      id="MaterialId"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5 dense"
                      ref={register}
                      value={itemData.itemNumber}
                      required
                      autoComplete="nope"
                    />
                    <input
                      type="text"
                      name="MaterialId"
                      hidden
                      value={itemData.itemNumber}
                      ref={register}
                      readOnly
                      required
                      autoComplete="nope"
                    />
                    <i
                      className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                      onClick={() => onItemNumberPrevious()}
                    ></i>
                    <i
                      className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                      onClick={() => onItemNumberNext()}
                    ></i>
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="Material"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Artikel
                    </label>
                    <input
                      type="text"
                      name="Material"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      value={itemData.description}
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                      readOnly
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="IsPrijsPerAfhaling"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Is prijs per afhaling?
                    </label>
                    <input
                      type="Checkbox"
                      name="IsPrijsPerAfhaling"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="Price"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prijs
                    </label>
                    <input
                      type="Number"
                      step="0.0001"
                      name="Price"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="StartDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      start datum
                    </label>
                    <input
                      type="Date"
                      name="StartDate"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="EndDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      eind datum
                    </label>
                    <input
                      type="Date"
                      name="EndDate"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="IsGeleverd"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Werd geleverd?
                    </label>
                    <input
                      type="Checkbox"
                      name="IsGeleverd"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="Transportkost"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Transportkost
                    </label>
                    <input
                      type="Number"
                      step="0.0001"
                      name="Transportkost"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                      ref={register}
                      tabIndex={-1}
                      autoComplete="nope"
                      defaultValue={0}
                    />
                  </div>
                </div>
                <div className="px-4 py-5 bg-white sm:p-6 w-10/12">
                  <h3 className="pb-4"></h3>

                  <table className="table-fixed text-left w-full">
                    <thead className="flex w-full">
                      <tr className="flex w-full">
                        <th className="w-1/12 px-4 py-2">Artikel nummer</th>
                        <th className="w-3/12 px-4 py-2">
                          Artikel Omschrijving
                        </th>
                        <th className="w-1/12 px-4 py-2">PPA</th>
                        <th className="w-2/12 px-4 py-2">Prijs</th>
                        <th className="w-2/12 px-4 py-2">Begin datum</th>
                        <th className="w-2/12 px-4 py-2">Eind Datum</th>
                        <th className="w-1/12 px-4 py-2">geleverd</th>
                        <th className="w-1/12 px-4 py-2">Transportkosten</th>
                        <th className="w-1/12 px-4 py-2"></th>
                      </tr>
                    </thead>
                    <tbody
                      className="bg-grey-light flex flex-col items-center overflow-y-scroll w-full"
                      style={{ height: "50vh" }}
                    >
                      {supplierItemPriceState?.map((item) => (
                        <tr className="flex w-full" key={item.id}>
                          <td className="border-r-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            {item.itemNumber}
                          </td>
                          <td className=" px-4 py-2 text-light-blue-600 font-medium w-3/12 truncate">
                            {item.itemDescription}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            {item.isPrijsPerAfhaling === true ? "X" : ""}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-2/12">
                            {new Date(item.endDate).toLocaleDateString() ===
                            "1-1-1" ? (
                              <span className="text-green">
                                {item.price.toFixed(4)}
                              </span>
                            ) : (
                              <span>{item.price.toFixed(4)}</span>
                            )}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-2/12">
                            {shortDate.format(new Date(item.beginDate))}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-2/12">
                            {new Date(item.endDate).toLocaleDateString() ===
                            "1-1-1"
                              ? ""
                              : shortDate.format(new Date(item.endDate))}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            {item.isGeleverd === true ? "X" : ""}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            {item.transportkost}
                          </td>
                          <td className="py-2 text-light-blue-600  w-1/12">
                            <FontAwesomeIcon
                              className=""
                              icon={faPen}
                              onClick={() => {
                                onPressEdit(item.id);
                              }}
                            />
                            <FontAwesomeIcon
                              className="ml-2"
                              icon={faTrash}
                              onClick={() => {
                                onPressDelete(item.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <span
                  onClick={() => supplier.isSupplierSelected()}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 mr-2"
                  style={{ cursor: "pointer" }}
                >
                  Terug
                </span>
                <input
                  type="submit"
                  value="Toevoegen"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                />
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default SupplierItemPriceList;
