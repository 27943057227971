/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import GewichtsNota from "./views/pages/gewichtsnota/gewichtsnota";
import Factuur from "./views/pages/factuur/factuur";
import Gewichtslijst from "./views/pages/gewichtslijst/gewichtslijst";
import CreateItem from "./views/pages/Item/CreateItem";
import CreateSupplier from "./views/pages/Supplier/CreateSupplier";
import Lijsten from "./views/pages/lijsten/lijsten";
// import Buttons from "views/pages/components/Buttons.js";
// import Calendar from "views/pages/Calendar.js";
// import Cards from "views/pages/components/Cards.js";
// import Charts from "views/pages/Charts.js";
// import Components from "views/pages/forms/Components.js";
// import Dashboard from "views/pages/dashboards/Dashboard.js";
// import Elements from "views/pages/forms/Elements.js";
// import Google from "views/pages/maps/Google.js";
// import Grid from "views/pages/components/Grid.js";
// import Icons from "views/pages/components/Icons.js";
// import Lock from "views/pages/examples/Lock.js";
import Login from "./views/pages/login/Login.js";
// import Notifications from "views/pages/components/Notifications.js";
// import Pricing from "views/pages/examples/Pricing.js";
// import Profile from "views/pages/examples/Profile.js";
// import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "./views/pages/register/Register.js";
import RequestResetPassword from "./views/pages/resetpassword/RequestResetPassword.js";
import ResetPassword from "./views/pages/resetpassword/ResetPassword.js";
import OutgoingResidues from "./views/pages/OutgoingResidues/OutgoingResidues";
import OutgoingResiduesList from "./views/pages/OutgoingResiduesList/OutgoingResiduesList";
import ApproveSupplierItem from "./views/pages/ApproveSupplierItem/ApproveSupplierItem";
import SupplierItemPrice from "./views/pages/SupplierItemPrice/SupplierItemPrice";
import CreateTransporteur from "./views/pages/Transporteur/CreateTransporteur";
// import RTLSupport from "views/pages/examples/RTLSupport.js";
// import Sortable from "views/pages/tables/Sortable.js";
// import Tables from "views/pages/tables/Tables.js";
// import Timeline from "views/pages/examples/Timeline.js";
// import Typography from "views/pages/components/Typography.js";
// import Validation from "views/pages/forms/Validation.js";
// import Vector from "views/pages/maps/Vector.js";
// import Widgets from "views/pages/Widgets.js";

// interface Routes{
//   collapse: boolean;
//   name: string;
//   icon: string;
//   state: string;
//   views: Array<Views>;
// }

// interface Views{
//   path:string;
//   name:string;
//   miniName:string;
//   component: React.FC;
//   layout:string;
// }

const routes = [
  // {
  //   collapse: true,
  //   name: "GewichtsNota",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   views: [
  //     {
  //       path: "/gewichtsnota",
  //       name: "gewichtsnota",
  //       miniName: "A",
  //       component: GewichtsNota,
  //       layout: "",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Examples",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "examplesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       miniName: "P",
  //       component: Pricing,
  //       layout: "/auth",
  //     },
      {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
        showInSidebar: false
      },
      {
        path: "register",
        name: "Register",
        component: Register,
        layout: "/",
        showInSidebar: false
      },
      {
        path: "/RequestPasswordReset",
        name: "Request Password Reset",
        component: RequestResetPassword,
        layout: "/auth",
        showInSidebar: false
      },
      {
        path: "/ResetPassword",
        name: "Reset Password",
        component: ResetPassword,
        layout: "/auth",
        showInSidebar: false
      },
  //     {
  //       path: "/lock",
  //       name: "Lock",
  //       miniName: "L",
  //       component: Lock,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       miniName: "T",
  //       component: Timeline,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       miniName: "P",
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       miniName: "RS",
  //       component: RTLSupport,
  //       layout: "/rtl",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       miniName: "B",
  //       component: Buttons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       miniName: "C",
  //       component: Cards,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid",
  //       name: "Grid",
  //       miniName: "G",
  //       component: Grid,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       miniName: "N",
  //       component: Notifications,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       miniName: "I",
  //       component: Icons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       miniName: "T",
  //       component: Typography,
  //       layout: "/admin",
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       miniName: "M",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/elements",
  //       name: "Elements",
  //       miniName: "E",
  //       component: Elements,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/components",
  //       name: "Components",
  //       miniName: "C",
  //       component: Components,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation",
  //       name: "Validation",
  //       miniName: "V",
  //       component: Validation,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/tables",
  //       name: "Tables",
  //       miniName: "T",
  //       component: Tables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sortable",
  //       name: "Sortable",
  //       miniName: "S",
  //       component: Sortable,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-bs-table",
  //       name: "React BS Tables",
  //       miniName: "RBT",
  //       component: ReactBSTables,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  {
    path: "gewichtsnota",
    name: "gewichtsnota",
    icon: "ni ni-calendar-grid-58 text-red",
    component: GewichtsNota,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "factuur",
    name: "factuur",
    icon: "ni ni-book-bookmark text-blue",
    component: Factuur,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "gewichtslijst",
    name: "gewichtslijst",
    icon: "ni ni-book-bookmark text-yellow",
    component: Gewichtslijst,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "lijsten",
    name: "lijsten",
    icon: "ni ni-bullet-list-67 text-orange",
    component: Lijsten,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "UitgaandeReststof",
    name: "Uitgaande Reststoffen",
    icon: "ni text-red",
    component: OutgoingResidues,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "UitgaandeReststoffen",
    name: "Uitgaande Reststoffen lijst",
    icon: "ni ni-book-bookmark text-yellow",
    component: OutgoingResiduesList,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "CreateItem",
    name: "Artikel aanmaken",
    icon: "ni ni-fat-add text-green",
    component: CreateItem,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "CreateSupplier",
    name: "Leverancier aanmaken",
    icon: "ni ni-fat-add text-green",
    component: CreateSupplier,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "ApproveSupplierItem",
    name: "Artikel goedkeuren",
    icon: "ni ni-fat-add text-green",
    component: ApproveSupplierItem,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "SupplierItemPrice",
    name: "ArtikelPrijs",
    icon: "ni ni-fat-add text-green",
    component: SupplierItemPrice,
    layout: "/",
    showInSidebar: true
  },
  {
    path: "Transporteur",
    name: "Transporteur aanmaken",
    icon: "ni ni-fat-add text-green",
    component: CreateTransporteur,
    layout: "/",
    showInSidebar: true
  },
];

export default routes;
