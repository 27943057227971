import axios from 'axios';
import { store } from 'react-notifications-component';

export function errorInterceptor() {
    axios.interceptors.response.use(null, (error) => {
        const { response } = error;
        if (!response) {
            // network error
            console.error(error);
            return;
        }
        if ([401, 403].includes(response.status) ) {
            // auto logout if 401 or 403 response returned from api
            localStorage.removeItem("jwt");
            store.addNotification({
                title: "Oops, iets lijkt niet ok",
                message: "Gelieve opnieuw in te loggen",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
                }
            });
            if ((window.location.pathname !== "/auth/login")) {
                document.location.href="/";
            }
        }
        else
        {
            store.addNotification({
                title: "Oops, iets lijkt niet ok",
                message: response.data.error,
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
                }
            });
        }
        console.error('ERROR:', response);
    });
}