import axios from "axios";
import React, {useState} from "react";
import { useForm, NestedValue } from 'react-hook-form';

const CreateSupplier: React.FC = () => {

  const [formLocationValues, setFormLocationValues] = useState([{ supplierLocationNumber: "", supplierLocationDescription : ""}])

  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });

  const onSubmit = (data: any) => {
    const nonEmptyFormLocationValues = removeEmptyLocationValues(formLocationValues)
    axios.post(process.env.REACT_APP_API_BASE_ADRESS +"Supplier/Create",
    {
        SupplierNumber: data.SupplierNumber,
        SupplierName: data.SupplierName,
        SupplierName2: data.SupplierName2,
        SupplierStreet: data.SupplierStreet,
        SupplierStreetNumber: data.SupplierStreetNumber,
        SupplierPostalCode: data.SupplierPostalCode,
        SupplierRegion: data.SupplierRegion,
        SupplierCountry: data.SupplierCountry,
        SupplierLanguage: data.SupplierLanguage,
        SupplierComLanguage: data.SupplierComLanguage,
        SupplierVATNumber: data.SupplierVATNumber,
        SupplierType: data.SupplierType,
        SupplierCurrency: data.SupplierCurrency,
        SupplierPhoneNumber1: data.SupplierPhoneNumber1,
        SupplierPhoneNumber2: data.SupplierPhoneNumber2,
        SupplierBankAccount1: data.SupplierBankAccount1,
        SupplierBankAccount2: data.SupplierBankAccount2,
        SupplierBookAccount: data.SupplierBookAccount,
        SupplierTermsOfPayment: data.SupplierTermsOfPayment,
        SupplierTransportCollection: data.SupplierTransportCollection,
        SupplierCustomerNumber: data.SupplierCustomerNumber,
        SupplierCase: data.SupplierCase,
        SupplierWeightList: data.SupplierWeightList,
        SupplierPurchaseNote: data.SupplierPurchaseNote,
        SupplierMemo: data.SupplierMemo,
        SupplierItemNumber: data.SupplierItemNumber,
        SupplierOVAMform: data.SupplierOVAMform,
        SupplierOldClothes: data.SupplierOldClothes,
        SupplierLocationNumbers:nonEmptyFormLocationValues,
        OriginType: data.OriginType,
        DestinationType: data.DestinationType
    }).then((response) =>{
      ClearFields();
      window.location.reload();
    })
    .catch((error)=>{
    })
  };

  const ClearFields = async()=>{

  }

  const removeEmptyLocationValues = (values:Array<any>) =>{
    return values.filter(value => value.supplierLocationNumber !== "" && value.supplierLocationDescription !== "");
  }

  let handleChange = (i:any, e:any) => {
    let newFormValues:any = [...formLocationValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormLocationValues(newFormValues);
  }

let addFormFields = () => {
  setFormLocationValues([...formLocationValues, { supplierLocationNumber: "", supplierLocationDescription: "" }])
  }

let removeFormFields = (i:any) => {
    let newFormValues = [...formLocationValues];
    newFormValues.splice(i, 1);
    setFormLocationValues(newFormValues)
}

  return (
    <>
  <div className="mt-10 sm:mt-0">
    <div className="md:grid md:grid-cols-8 md:gap-6">
      <div className="md:col-span-1"/>
      <div className="mt-5 md:mt-0 md:col-span-6">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                    <label htmlFor="SupplierNumber" className="block text-sm font-medium text-gray-700">Leverancier nummer *</label>
                  <input type="text" name="SupplierNumber" id="SupplierNumber" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" required/>                
                </div>
  
                <div className="col-span-2 sm:col-span-2">
                  <label htmlFor="SupplierName" className="block text-sm font-medium text-gray-700">Leveranciers naam *</label>
                  <input type="text" name="SupplierName" id="SupplierName" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5" ref={register} autoComplete="nope" required/>
                </div>
                <div className="col-span-2 sm:col-span-2">
                  <label htmlFor="SupplierName2" className="block text-sm font-medium text-gray-700">Leveranciers naam2 </label>
                  <input type="text" name="SupplierName2" id="SupplierName2" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5" ref={register} autoComplete="nope"/>
                </div>
                <div className="col-span-3 sm:col-span-3">
                    <div className="grid grid-cols-5 gap-6">
                        <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="SupplierStreet" className="block text-sm font-medium text-gray-700">Leveranciers straat </label>
                            <input type="text" name="SupplierStreet" id="SupplierStreet" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md " ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierStreetNumber" className="block text-sm font-medium text-gray-700">straat nr</label>
                            <input type="number" name="SupplierStreetNumber" id="SupplierStreetNumber" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierPostalCode" className="block text-sm font-medium text-gray-700">Postcode </label>
                            <input type="text" name="SupplierPostalCode" id="SupplierPostalCode" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="SupplierRegion" className="block text-sm font-medium text-gray-700">Gemeente </label>
                            <input type="text" name="SupplierRegion" id="SupplierRegion" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                    </div>                    
                </div>
                <div className="col-span-2 sm:col-span-2">
                    <div>
                        <label htmlFor="SupplierVATNumber" className="block text-sm font-medium text-gray-700">Btw nummer </label>
                        <input type="text" name="SupplierVATNumber" id="SupplierVATNumber" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                    </div>
                    <div>
                        <label htmlFor="SupplierType" className="block text-sm font-medium text-gray-700">Soort leverancier </label>
                        <input type="text" name="SupplierType" id="SupplierType" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                    </div>
                </div>
                <div className="col-span-1 sm:col-span-1"/>
                
                <div className="col-span-6 sm:col-span-6">
                    <div className="grid grid-cols-5 gap-6">
                        <div>
                            <label htmlFor="SupplierCountry" className="block text-sm font-medium text-gray-700">Land *</label>
                            <input type="text" name="SupplierCountry" id="SupplierCountry" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div>
                            <label htmlFor="SupplierLanguage" className="block text-sm font-medium text-gray-700">Taal </label>
                            <input type="text" name="SupplierLanguage" id="SupplierLanguage" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div>
                            <label htmlFor="SupplierComLanguage" className="block text-sm font-medium text-gray-700">Com Taal </label>
                            <input type="text" name="SupplierComLanguage" id="SupplierComLanguage" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div>
                            <label htmlFor="SupplierCurrency" className="block text-sm font-medium text-gray-700">Munt *</label>
                            <input type="text" name="SupplierCurrency" id="SupplierCurrency" defaultValue="EUR" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" required/>
                        </div>
                    </div>
                </div>  
                <div className="col-span-6 sm:col-span-6">
                    <div className="grid grid-cols-5 gap-6">
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierPhoneNumber1" className="block text-sm font-medium text-gray-700">Telefoon Nr *</label>
                            <input type="text" name="SupplierPhoneNumber1" id="SupplierPhoneNumber1" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" required/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierBankAccount1" className="block text-sm font-medium text-gray-700">Rekenings Nr *</label>
                            <input type="text" name="SupplierBankAccount1" id="SupplierBankAccount1" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" required/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierPhoneNumber2" className="block text-sm font-medium text-gray-700">Telefoon Nr2</label>
                            <input type="text" name="SupplierPhoneNumber2" id="SupplierPhoneNumber2" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierBankAccount2" className="block text-sm font-medium text-gray-700">Rekenings Nr2</label>
                            <input type="text" name="SupplierBankAccount2" id="SupplierBankAccount2" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierBookAccount" className="block text-sm font-medium text-gray-700">Boek rekening</label>
                            <input type="text" name="SupplierBookAccount" id="SupplierBookAccount" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierTermsOfPayment" className="block text-sm font-medium text-gray-700">Betalings voorwaarden</label>
                            <input type="text" name="SupplierTermsOfPayment" id="SupplierTermsOfPayment" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierTransportCollection" className="block text-sm font-medium text-gray-700">Transport afhaling</label>
                            <input type="text" name="SupplierTransportCollection" id="SupplierTransportCollection" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierCustomerNumber" className="block text-sm font-medium text-gray-700">Ons klantennummer</label>
                            <input type="text" name="SupplierCustomerNumber" id="SupplierCustomerNumber" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierCase" className="block text-sm font-medium text-gray-700">Bakken</label>
                            <input type="text" name="SupplierCase" id="SupplierCase" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierWeightList" className="block text-sm font-medium text-gray-700">Gewichtslijst</label>
                            <select name="SupplierWeightList" id="SupplierWeightList" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope">
                              <option value="0">Nee</option>
                              <option value="1">Ja</option>
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierPurchaseNote" className="block text-sm font-medium text-gray-700">Aankoopborderel</label>
                            <input type="text" name="SupplierPurchaseNote" id="SupplierPurchaseNote" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierMemo" className="block text-sm font-medium text-gray-700">Memo</label>
                            <input type="text" name="SupplierMemo" id="SupplierMemo" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierItemNumber" className="block text-sm font-medium text-gray-700">Artikel nummer</label>
                            <input type="text" name="SupplierItemNumber" id="SupplierItemNumber" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope"/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierOVAMform" className="block text-sm font-medium text-gray-700">OVAM formulier *</label>
                            <input type="text" name="SupplierOVAMform" id="SupplierOVAMform" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ref={register} autoComplete="nope" required/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="OriginType" className="block text-sm font-medium text-gray-700">Oorsprongs Type</label>
                            <select name="OriginType" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" ref={register} defaultValue="N" autoComplete="nope">
                                <option value ="BelgischeVestiging">Belgische vestiging</option>
                                <option value ="BuitenlandseVestiging">Buitenlandse vestiging</option>
                                <option value ="Trader">Trader</option>
                                <option value ="Inzamelronde">Inzamelronde</option>
                                <option value ="Werf">Werf</option>
                                <option value ="GeenOnderneming">Geen onderneming</option>
                                <option value ="Particulieren">Particulieren</option>
                            </select>     
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="DestinationType" className="block text-sm font-medium text-gray-700">Bestemmings Type</label>
                            <select name="DestinationType" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" ref={register} defaultValue="N" autoComplete="nope">
                                <option value ="BelgischeVestiging">Belgische vestiging</option>
                                <option value ="BuitenlandseVestiging">Buitenlandse vestiging</option>
                                <option value ="Trader">Trader</option>
                                <option value ="Werf">Werf</option>
                                <option value ="DispersGebruik">Dispers gebruik</option>
                                <option value ="GeenOnderneming">Geen onderneming</option>
                                <option value ="Particulieren">Particulieren</option>
                            </select>     
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="SupplierOldClothes" className="block text-sm font-medium text-gray-700">Oude kleren</label>
                            <select name="SupplierOldClothes" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" ref={register} defaultValue="N" autoComplete="nope">
                                <option value ="Y">Yes</option>
                                <option value ="N">No</option>
                            </select>     
                        </div>
                    </div>
                    <div  >
                    {formLocationValues.map((element:any, index:any) => (
                      <div className="grid grid-cols-5 gap-6 mt-3" key={index}>
                        <div className="col-span-2 sm:col-span-2">
                          <label htmlFor="supplierLocationNumber" className="block text-sm font-medium text-gray-700">Vestigings Nummer</label>
                          <input type="text" name="supplierLocationNumber" id="supplierLocationNumber" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"value={element.locationNumber || ""} onChange={e => handleChange(index, e)}/>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label htmlFor="supplierLocationDescription" className="block text-sm font-medium text-gray-700">Vestigings Omschrijving</label>
                          <input type="text" name="supplierLocationDescription" id="supplierLocationDescription" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" value={element.locationDescription || ""} onChange={e => handleChange(index, e)}/>
                        </div>
                        {
                          index ? 
                            <div className="col-span-1 sm:col-span-1 mt-auto w-2/4 text-center align-middle">
                              <span className="ni ni-fat-remove text-red" onClick={() => removeFormFields(index)}></span>
                            </div>
                          : 
                            <div className="col-span-1 sm:col-span-1 mt-auto w-2/4 text-center align-middle">
                              <span className="ni ni-fat-add text-blue" onClick={() => addFormFields()}></span>
                            </div>
                        }
                        
                      </div>
                    ))}
                    </div>
                </div>
                
                </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <input type="submit" value="Save" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"/>

            </div>
          </div>
        </form>
      </div>
      <div className="md:col-span-1"/>
    </div>
  </div>
</>

  );
};

export default CreateSupplier;