import {
  Transporteur_Create,
  Transporteur_GetById,
  Transporteur_Update,
  Transporteur_GetAll,
} from "../../../ApiEndpoints";
import { useForm, NestedValue } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal/modal";
import { Transporteur } from "../../../Interfaces/Transporteur";

const CreateTransporteur: React.FC = () => {
  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });
  const [transporteurList, setTransporteurList] = useState<Array<Transporteur>>(
    []
  );
  const [isOn, setIsOn] = useState<boolean>(false);
  const [editableTransporteur, setEditableTransporteur] =
    useState<Transporteur>({
      id: "test",
      naam: "55555",
      straat: "",
      huisnummer: "",
      gemeente: "",
      postcode: "",
      land: "",
      identificatienummer: "",
      ondernemingnummer: "",
      ovamnummer: "",
    });
  useEffect(() => {
    RetrieveTransporteurList();
  }, []);

  const onSubmit = (data: any) => {
    axios
      .post(Transporteur_Create, {
        Naam: data.Naam,
        Straat: data.Straat,
        Huisnummer: data.Huisnummer,
        Gemeente: data.Gemeente,
        Postcode: data.Postcode,
        Land: data.Land,
        Identificatienummer: data.Identificatienummer,
        Ondernemingnummer: data.Ondernemingnummer,
        Ovamnummer: data.Ovamnummer,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const onPressEdit = async (transporteurId: string) => {
    axios
      .get(Transporteur_GetById, {
        params: {
          Id: transporteurId,
        },
      })
      .then((response) => {
        setEditableTransporteur(response.data);
        setIsOn(true);
      })
      .catch((error) => {});
  };

  const RetrieveTransporteurList = async () => {
    axios
      .get(Transporteur_GetAll)
      .then((response) => {
        setTransporteurList(response.data);
      })
      .catch((error) => {});
  };

  const onPressCancel = () => {
    setIsOn(false);
  };

  const onPressSubmit = () => {
    axios
      .put(Transporteur_Update, {
        Id: editableTransporteur.id,
        Naam: editableTransporteur.naam,
        Straat: editableTransporteur.straat,
        Huisnummer: editableTransporteur.huisnummer,
        Gemeente: editableTransporteur.gemeente,
        Postcode: editableTransporteur.postcode,
        Land: editableTransporteur.land,
        Identificatienummer: editableTransporteur.identificatienummer,
        Ondernemingnummer: editableTransporteur.ondernemingnummer,
        Ovamnummer: editableTransporteur.ovamnummer,
      })
      .then((response) => {
        setIsOn(false);
        window.location.reload();
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="mt-10 sm:mt-0">
        {editableTransporteur.id !== "test" ? (
          <Modal
            key={editableTransporteur.id}
            isOn={isOn}
            onCancel={onPressCancel}
            onSubmit={onPressSubmit}
            title={"Artikel aanpassen"}
          >
            <div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="ModaltransporteurNaam"
                    className="block text-sm font-medium text-gray-700"
                  >
                    TransporteurNaam
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        naam: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModaltransporteurNaam"
                    id="ModaltransporteurNaam"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.naam}
                  />
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="Modalstraat"
                    className="block text-sm font-medium text-gray-700"
                  >
                    straat
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        straat: ev.target.value,
                      })
                    }
                    type="text"
                    name="Modalstraat"
                    id="Modalstraat"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.straat}
                  />
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="Modalhuisnummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    huisnummer
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        huisnummer: ev.target.value,
                      })
                    }
                    type="text"
                    name="Modalhuisnummer"
                    id="Modalhuisnummer"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.huisnummer}
                  />
                </div>
              </div>
              <br />
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="Modalgemeente"
                    className="block text-sm font-medium text-gray-700"
                  >
                    gemeente
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        gemeente: ev.target.value,
                      })
                    }
                    type="text"
                    name="Modalgemeente"
                    id="Modalgemeente-1"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.gemeente}
                  />
                  <label
                    htmlFor="Modalpostcode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    postcode
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        postcode: ev.target.value,
                      })
                    }
                    type="text"
                    name="Modalpostcode"
                    id="Modalpostcode"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.postcode}
                  />
                  <label
                    htmlFor="Modalland"
                    className="block text-sm font-medium text-gray-700"
                  >
                    land
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        land: ev.target.value,
                      })
                    }
                    type="text"
                    name="Modalland"
                    id="Modalland"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.land}
                  />
                  <label
                    htmlFor="ModalIdentificatienummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Identificatienummer
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        identificatienummer: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalIdentificatienummer"
                    id="ModalIdentificatienummer"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.identificatienummer}
                  />
                  <label
                    htmlFor="ModalOndernemingnummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Ondernemingnummer
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        ondernemingnummer: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalOndernemingnummer"
                    id="ModalOndernemingnummer"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.ondernemingnummer}
                  />
                  <label
                    htmlFor="ModalOvamnummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Ovamnummer
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableTransporteur({
                        ...editableTransporteur,
                        ovamnummer: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalOvamnummer"
                    id="ModalOvamnummer"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableTransporteur.ovamnummer}
                  />
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          <></>
        )}
        <div className="md:grid md:grid-cols-8 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-6">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
              <div className="shadow overflow-hidden sm:rounded-md flex">
                <div className="px-4 py-5 bg-white sm:p-6 w-4/7">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="Naam"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Transporteur naam *
                      </label>
                      <input
                        type="text"
                        name="Naam"
                        id="Naam"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        autoComplete="nope"
                        required
                      />
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="Straat"
                        className="block text-sm font-medium text-gray-700"
                      >
                        straat
                      </label>
                      <input
                        type="text"
                        name="Straat"
                        id="Straat"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                        required
                      />
                    </div>
                    <div className="col-span-1 sm:col-span-1">
                      <label
                        htmlFor="Huisnummer"
                        className="block text-sm font-medium text-gray-700"
                      >
                        huisnummer
                      </label>
                      <input
                        type="text"
                        name="Huisnummer"
                        id="Huisnummer"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="Gemeente"
                            className="block text-sm font-medium text-gray-700"
                          >
                            gemeente
                          </label>
                          <input
                            type="text"
                            name="Gemeente"
                            id="Gemeente"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md "
                            ref={register}
                            autoComplete="nope"
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="Postcode"
                            className="block text-sm font-medium text-gray-700"
                          >
                            postcode
                          </label>
                          <input
                            type="text"
                            name="Postcode"
                            id="Postcode"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md "
                            ref={register}
                            autoComplete="nope"
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="Land"
                            className="block text-sm font-medium text-gray-700"
                          >
                            land
                          </label>
                          <input
                            type="text"
                            name="Land"
                            id="Land"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            ref={register}
                            autoComplete="nope"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-3 sm:col-span-3">
                          <label
                            htmlFor="Identificatienummer"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Identificatienummer
                          </label>
                          <input
                            type="text"
                            name="Identificatienummer"
                            id="Identificatienummer"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            ref={register}
                            autoComplete="nope"
                          />
                        </div>
                        <div className="col-span-3 sm:col-span-3">
                          <label
                            htmlFor="Ondernemingnummer"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ondernemingnummer
                          </label>
                          <input
                            type="text"
                            name="Ondernemingnummer"
                            id="Ondernemingnummer"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            ref={register}
                            autoComplete="nope"
                          />
                        </div>
                        <div className="col-span-3 sm:col-span-3">
                          <label
                            htmlFor="Ovamnummer"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ovamnummer
                          </label>
                          <input
                            type="text"
                            name="Ovamnummer"
                            id="Ovamnummer"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            ref={register}
                            autoComplete="nope"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-5 bg-white sm:p-6 w-3/7 ">
                  <table className="table-fixed text-left">
                    <thead className="flex w-full">
                      <tr className="flex w-full">
                        <th className="w-1/12 px-4 py-2"></th>
                        <th className="w-10/12 px-4 py-2">Transporteur</th>
                      </tr>
                    </thead>
                    <tbody
                      className="bg-grey-light flex flex-col items-center overflow-y-scroll w-full"
                      style={{ height: "50vh" }}
                    >
                      {transporteurList.map((transporteur) => (
                        <tr className="flex w-full" key={transporteur.id}>
                          <td className="px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            <FontAwesomeIcon
                              icon={faPen}
                              onClick={() => {
                                onPressEdit(transporteur.id);
                              }}
                            />
                          </td>
                          <td className="px-4 py-2 text-light-blue-600 font-medium w-10/12">
                            {transporteur.naam}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <input
                  type="submit"
                  value="Save"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                />
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default CreateTransporteur;
