import axios from 'axios';
import { parseJwt } from "../Utils/UtilFunctions.js";



export const JwtInterceptor = () => {
    let jwt = localStorage.getItem("jwt");

    const checkIfLoggedIn = () => {
        jwt = localStorage.getItem("jwt");
        if(jwt){
            const jwtJson = parseJwt(jwt);

            if((jwt !== "" || jwt !== undefined ) && ( jwtJson.exp !== "" || Date.now() <= new Date(jwtJson.exp).getTime() ))
                return true
            else{
                localStorage.removeItem("jwt");
                return false
            }
        }
        else{
            localStorage.removeItem("jwt");
            return false
        }
    }

    axios.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url
        const isLoggedIn = checkIfLoggedIn();
        const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_BASE_ADRESS);

        if (isLoggedIn && isApiUrl) {
            request.headers.common.Authorization = `Bearer ${jwt}`;
        }
        return request;
    });
}
