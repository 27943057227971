export const UrlBase: string|undefined = process.env.REACT_APP_API_BASE_ADRESS;
export const Weight_WeightNotes_Invoice: string = UrlBase + "Weight/WeightNotes/Invoice";
export const Weight_AddInvoiceToWeightNote: string = UrlBase +"Weight/AddInvoiceToWeightNote";
export const Supplier_GetSupplierById: string = UrlBase +"Supplier/GetSupplierById";
export const Supplier_GetSupplierByName: string = UrlBase +"Supplier/GetSupplierByName";
export const Supplier_GetSupplierByNameByDirection: string = UrlBase  +"Supplier/GetSupplierByNameByDirection";
export const Weight_WeightNotes: string = UrlBase  +"Weight/WeightNotes";
export const Weight_WeightNotes_All: string = UrlBase  +"Weight/WeightNotes/All";
export const Weight_WeightNotes_Document: string = UrlBase  +"Weight/Weightnotes/Document";
export const Weight_CreateWeightNote: string = UrlBase  +"Weight/CreateWeightNote";
export const Item_GetItemById: string = UrlBase  +"Item/GetItemById";
export const Item_GetItemByIdByDirection: string = UrlBase  +"Item/GetItemByIdByDirection";
export const Item_GetItemByIdAndSupplier: string = UrlBase  +"Item/GetItemByIdAndSupplier";
export const Item_GetItemByIdAndSupplierByDirection: string = UrlBase  +"Item/GetItemByIdAndSupplierByDirection";
export const Item_Create: string = UrlBase  +"Item/Create";
export const Item_List: string = UrlBase  +"Item/List";
export const Item_Update: string = UrlBase  +"Item/Update";
export const Weight_Weightnotes_DocumentToEmail: string = UrlBase  +"Weight/Weightnotes/DocumentToEmail";
export const Supplier_EnvironmentalRegister: string = UrlBase  +"Supplier/EnvironmentalRegister";
export const Supplier_MatisEnvironmentalRegister_Verwerker: string = UrlBase  +"Supplier/MatisEnvironmentalRegister/Verwerker";
export const Supplier_MatisEnvironmentalRegister_Ihm: string = UrlBase  +"Supplier/MatisEnvironmentalRegister/Ihm";
export const OutgoingResidues_Create: string = UrlBase  +"OutgoingResidues/Create";
export const OutgoingResidues_GetById: string = UrlBase  +"OutgoingResidues/GetById";
export const OutgoingResidues_GetList: string = UrlBase  +"OutgoingResidues/List";
export const OutgoingResidues_GetList_All: string = UrlBase  +"OutgoingResidues/List/All";
export const SupplierItem_GetList: string = UrlBase  +"SupplierItem/List";
export const SupplierItem_baseUrl: string = UrlBase  +"SupplierItem";
export const SupplierItemPrice_GetById: string = UrlBase + "SupplierItemPrice/GetById";
export const SupplierItemPrice_GetBySupplierId: string = UrlBase + "SupplierItemPrice/GetBySupplierId";
export const SupplierItemPrice_GetByItemId: string = UrlBase + "SupplierItemPrice/GetByItemId";
export const SupplierItemPrice_BaseUrl: string = UrlBase + "SupplierItemPrice";

export const Transporteur_Create: string = UrlBase + "Transporteur";
export const Transporteur_Delete: string = UrlBase + "Transporteur";
export const Transporteur_Update: string = UrlBase + "Transporteur";
export const Transporteur_GetById: string = UrlBase + "Transporteur";
export const Transporteur_GetAll: string = UrlBase + "Transporteur/all";

export const Documents: string = UrlBase + "Documents/";

export const Documents_GetPricelist: string = Documents + "GetPricelist";
export const Documents_GetUitgaandeAfvalstoffenLijst: string = Documents + "UitgaandeAfvalstoffenLijst";
export const Documents_GetGoedgekeurdeArtikelsPerLeverancierLijst: string = Documents + "GoedgekeurdeArtikelsPerLeverancier";


export const Auth_Login: string = UrlBase  +"Authenticate/Login";
export const Auth_Register: string = UrlBase  +"Authenticate/Register";
export const Auth_Reset_Password: string = UrlBase  +"Authenticate/ResetPassword";
export const Auth_Request_Reset_Password: string = UrlBase  +"Authenticate/RequestResetPassword";

