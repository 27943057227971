import React, { useState } from 'react';

import { EditingState } from '@devexpress/dx-react-grid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,  
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const getRowId = row => row.id;

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

const WeightFormatter = (props) => (
    props.row.bookedIn?<b style={{ color: 'green' }}>
  {props.value}
</b>:
  <b style={{ color: 'red' }}>
    {props.value}
  </b>
);

const WeightTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={WeightFormatter}
    {...props}
  />
);

function pad2(n) {
  return (n < 10 ? '0' : '') + n;
}

const DateFormatter = (props) => (

    pad2(new Date(props.value).getDate()) + "-" + pad2(new Date(props.value).getMonth()+1) + "-" + new Date(props.value).getFullYear()
);

const DateTypeProvider = props => (
<DataTypeProvider
  formatterComponent={DateFormatter}
  {...props}
/>
);



const CustomTable = props => {
  const [columns] = useState(props.Columns);
  const [columnExtension] = useState(props.columnExtension)
  const [rows, setRows] = useState(props.data);
  const [WeightColumns] = useState(['weight']);
  const [DateColumns] = useState(['date']);
  const [editingCells, setEditingCells] = useState([]);
  const [editingStateColumnExtensions] = useState([
    { columnName: 'weightNoteNumber', editingEnabled: false },
  ]);
  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0
        ? Math.max(rows[rows.length - 1].id, rows[0].id) + 1
        : 0;
      changedRows = [
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
        ...rows,
      ];
      setEditingCells([{ rowId: startingAddedId, columnName: columns[0].name }]);
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }

    setRows(changedRows);
    props.onWeightNoteChange(changedRows)
  };

  const addEmptyRow = () => commitChanges({ added: [{}] });

  return (
    <div className="card">
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <WeightTypeProvider
          for={WeightColumns}
        />        
        <DateTypeProvider
        for={DateColumns}
        />
        <SortingState />
        <IntegratedSorting />

        <EditingState
          onCommitChanges={commitChanges}
          editingCells={editingCells}
          onEditingCellsChange={setEditingCells}
          addedRows={[]}
          onAddedRowsChange={addEmptyRow}
          columnExtensions={editingStateColumnExtensions}
        />
        <VirtualTable 
        cellComponent={FocusableCell}
        columnExtensions={columnExtension}
        />
        <TableHeaderRow showSortingControls />
        <TableInlineCellEditing selectTextOnEditStart />
        {props.delete ?
          <TableEditColumn
          showAddCommand
          showDeleteCommand
          width="80"
          />
        :
          <TableEditColumn
          showAddCommand
          width="70"
          />
        }        
      </Grid>
    </div>
  );
};
export default CustomTable;