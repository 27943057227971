import { withRouter } from "react-router-dom";
import { parseJwt } from "../Utils/UtilFunctions.js";
import { useHistory } from "react-router-dom";

const AuthVerify = () => {
    const history = useHistory();
    history.listen(() => {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      const decodedJwt = parseJwt(jwt);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem("jwt");
        if ((window.location.pathname !== "/auth/login")) {
            document.location.href="/";
        }
      }
    }
  });
  return <div></div>;
};
export default withRouter(AuthVerify);