import axios from "axios";
import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useForm, NestedValue } from "react-hook-form";
import {
  Item_GetItemByIdAndSupplier,
  Item_GetItemByIdAndSupplierByDirection,
  Supplier_GetSupplierById,
  Supplier_GetSupplierByName,
  Supplier_GetSupplierByNameByDirection,
  Weight_CreateWeightNote,
  Transporteur_GetAll,
  Transporteur_Create,
} from "../../../ApiEndpoints";
import Modal from "../../../components/Modal/modal";
import Select from "react-select";
import { Transporteur } from "../../../Interfaces/Transporteur";
import { SupplierData } from "../../../Interfaces/SupplierData";

export interface ItemData {
  itemNumber: string;
  description: string;
}

export interface FormData {
  Date: string;
  Time: string;
  DateTime: string;
  SupplierNumber: string;
  SupplierName: string;
  ItemNumber: string;
  ItemName: string;
  Weight: string;
  Transporter: string;
  Description: string;
  UnloadingPlace: string;
  SupplierLocationId: string;
  VerpakkingGewicht: string;
  VerpakkingBeschrijving: string;
}

const GewichtsNota: React.FC = () => {
  const [supplierData, setSupplierData] = useState<SupplierData>({
    supplierNumber: "",
    supplierName2: "",
    supplierName1: "",
    supplierStreet: "",
    supplierStreetNumber: "",
    supplierPostalCode: "",
    supplierCity: "",
    supplierCountry: "",
    supplierVAT: "",
    supplierLocationInfo: [],
  });
  const [itemData, setItemData] = useState<ItemData>({
    itemNumber: "",
    description: "",
  });
  const [weightData, setWeightData] = useState<number | string>("");
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [verpakkingGewicht, setVerpakkingGewicht] = useState<number | string>(
    ""
  );
  const [verpakkingBeschrijving, setVerpakkingBeschrijving] = useState<
    string | undefined
  >(undefined);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>(
    (new Date().getHours() < 10
      ? "0" + new Date().getHours()
      : new Date().getHours()) +
      ":" +
      (new Date().getMinutes() + 1 < 10
        ? "0" + (new Date().getMinutes() + 1)
        : new Date().getMinutes() + 1)
  );
  const [selectedSupplierLocationNumber, setSelectedSupplierLocationNumber] =
    useState<string>("");
  const [formData, setFormData] = useState<FormData>({
    Date: "",
    Time: "",
    DateTime: "",
    SupplierNumber: "",
    SupplierName: "",
    ItemNumber: "",
    ItemName: "",
    Weight: "",
    Transporter: "",
    Description: "",
    UnloadingPlace: "",
    SupplierLocationId: "",
    VerpakkingGewicht: "",
    VerpakkingBeschrijving: "",
  });
  const [TransporteurLijst, setTransporteurLijst] = useState<
    Array<Transporteur>
  >([]);
  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });
  const [
    createTransporteurBasedOnLeverancier,
    setCreateTransporteurBasedOnLeverancier,
  ] = useState<boolean>(false);
  const [geselecteerdeTransporteur, setGeselecteerdeTransporteur] =
    useState<string>();

  useEffect(() => {
    document.getElementById("Date")!.focus();
  }, []);

  useEffect(() => {
    RetrieveTransporteurs();
  }, []);

  useEffect(() => {
    setGeselecteerdeTransporteur(TransporteurLijst[0]?.naam);
  }, [TransporteurLijst]);

  const RetrieveTransporteurs = async () => {
    await axios
      .get<Array<Transporteur>>(Transporteur_GetAll)
      .then((response) => {
        setTransporteurLijst(response.data);
      });
  };

  const onSubmit = async (data: any) => {
    let transporteurId: string | undefined;
    if (displayModal === false) {
      setFormData({
        Date: data.Date,
        Time: data.Time,
        DateTime: data.Date + "T" + data.Time,
        SupplierNumber: data.SupplierNr,
        SupplierName: data.SupplierName,
        ItemNumber: data.MaterialId,
        ItemName: data.Material,
        Weight: data.Weight,
        Transporter: createTransporteurBasedOnLeverancier
          ? supplierData.supplierName1
          : geselecteerdeTransporteur ?? supplierData.supplierName1,
        Description: data.Description,
        UnloadingPlace: data.UnloadingPlace,
        SupplierLocationId: data.SupplierLocationNumber,
        VerpakkingGewicht: data.VerpakkingGewicht,
        VerpakkingBeschrijving: data.VerpakkingBeschrijving,
      });
      ToggleModal();
    } else {
      let createdTransporteurId = null;
      if (
        createTransporteurBasedOnLeverancier &&
        !DoesLeverancierAlreadyExistsAsTransporteur(supplierData)
      ) {
        await axios
          .post(Transporteur_Create, {
            naam: supplierData.supplierName1,
            straat: supplierData.supplierStreet,
            huisnummer: supplierData.supplierStreetNumber,
            gemeente: supplierData.supplierCity,
            postcode: supplierData.supplierPostalCode,
            land: supplierData.supplierCountry,
            ondernemingnummer: supplierData.supplierVAT,
          })
          .then((response) => {
            createdTransporteurId = response.data;
          });
      } else {
        transporteurId = TransporteurLijst?.find(
          (_: Transporteur) => _.naam === formData.Transporter
        )?.id;
      }

      await axios
        .post(Weight_CreateWeightNote, {
          date: formData.DateTime,
          supplierNumber: formData.SupplierNumber,
          itemNumber: formData.ItemNumber,
          weight: formData.Weight,
          transporter: createdTransporteurId ?? transporteurId,
          description: formData.Description,
          unloadingPlace: formData.UnloadingPlace,
          supplierLocationId: formData.SupplierLocationId,
          transporteurId: createdTransporteurId ?? transporteurId,
          verpakkingGewicht: formData.VerpakkingGewicht,
          verpakkingBeschrijving: formData.VerpakkingBeschrijving,
        })
        .then((response) => {
          ClearFields();
          alert("Uw leverbon nummer is: " + response.data);

          // reset Transporteur dropdown
          setCreateTransporteurBasedOnLeverancier(true);
          setCreateTransporteurBasedOnLeverancier(false);

          document.getElementById("Date")!.focus();
        })
        .catch((error) => {
          setDisplayModal(false);
        });
    }
  };

  const DoesLeverancierAlreadyExistsAsTransporteur = (
    supplier: SupplierData
  ) => {
    var transporteur = TransporteurLijst.find(
      (_) => _.naam === supplier.supplierName1
    );

    if (transporteur === undefined) {
      return false;
    }

    if (transporteur.straat !== supplier.supplierStreet) {
      return false;
    }

    if (transporteur.huisnummer !== supplier.supplierStreet) {
      return false;
    }
    if (transporteur.land !== supplier.supplierCountry) {
      return false;
    }
    if (transporteur.gemeente !== supplier.supplierCity) {
      return false;
    }
    if (transporteur.postcode !== supplier.supplierPostalCode) {
      return false;
    }
    if (transporteur.ondernemingnummer !== supplier.supplierVAT) {
      return false;
    }

    return true;
  };

  const ToggleModal = () => {
    setDisplayModal(!displayModal);
  };

  const onSupplierNumberChange = async (supplierNr: string) => {
    let supplierDataLocal = supplierData;
    supplierDataLocal.supplierNumber = supplierNr;
    setSupplierData(supplierDataLocal);
    await axios
      .get<SupplierData>(Supplier_GetSupplierById, {
        params: {
          SupplierNumber: supplierNr,
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
        resetArtikelData();
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: supplierData.supplierNumber,
          supplierName2: "",
          supplierName1: "",
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onSupplierNameChange = async (supplierName: string) => {
    await axios
      .get<SupplierData>(Supplier_GetSupplierByName, {
        params: {
          SupplierName: supplierName.trim(),
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
        document.getElementById("SupplierName")!.focus();
        resetArtikelData();
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: "",
          supplierName2: "",
          supplierName1: supplierData.supplierName1,
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onMaterialIdChange = async (ItemNumber: string) => {
    await axios
      .get<ItemData>(Item_GetItemByIdAndSupplier, {
        params: {
          ItemNumber: ItemNumber,
          SupplierNumber: supplierData.supplierNumber,
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const ClearFields = async () => {
    setSupplierData({
      supplierNumber: "    ",
      supplierName2: "",
      supplierName1: "    ",
      supplierStreet: "",
      supplierStreetNumber: "",
      supplierPostalCode: "",
      supplierCity: "",
      supplierCountry: "",
      supplierVAT: "",
      supplierLocationInfo: [],
    });
    setSelectedSupplierLocationNumber("");
    setItemData({
      itemNumber: "",
      description: "",
    });

    setWeightData("");

    setDescription("");
    setVerpakkingBeschrijving("");
    setVerpakkingGewicht("");
    setDisplayModal(false);
    setCurrentTime(
      (new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours()) +
        ":" +
        (new Date().getMinutes() + 1 < 10
          ? "0" + (new Date().getMinutes() + 1)
          : new Date().getMinutes() + 1)
    );
  };

  const onChangeDescription = (desc: string) => {
    setDescription(desc);
  };

  const onChangeWeight = (weight: number) => {
    setWeightData(weight);
  };

  const onChangeVerpakkingBeschrijving = (desc: string) => {
    setVerpakkingBeschrijving(desc);
  };

  const onChangeVerpakkingGewicht = (weight: number) => {
    setVerpakkingGewicht(weight);
  };

  const onSupplierNumberIncrease = () => {
    onSupplierNumberChange(
      (Number(supplierData.supplierNumber) + 1).toString()
    );
  };

  const onSupplierNumberDecrease = () => {
    if (
      supplierData.supplierNumber === "0" ||
      supplierData.supplierNumber === ""
    ) {
      return;
    }
    onSupplierNumberChange(
      (Number(supplierData.supplierNumber) - 1).toString()
    );
  };

  const onSupplierNameNext = async () => {
    await axios
      .get<SupplierData>(Supplier_GetSupplierByNameByDirection, {
        params: {
          SupplierName: supplierData.supplierName1,
          Direction: "Asc",
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
        resetArtikelData();
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: "",
          supplierName2: "",
          supplierName1: supplierData.supplierName1,
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onSupplierNamePrevious = async () => {
    await axios
      .get<SupplierData>(Supplier_GetSupplierByNameByDirection, {
        params: {
          SupplierName: supplierData.supplierName1,
          Direction: "Desc",
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        initialiseSupplierLocationNumber(response);
        resetArtikelData();
      })
      .catch((error) => {
        setSupplierData({
          supplierNumber: "",
          supplierName2: "",
          supplierName1: supplierData.supplierName1,
          supplierStreet: "",
          supplierStreetNumber: "",
          supplierPostalCode: "",
          supplierCity: "",
          supplierCountry: "",
          supplierVAT: "",
          supplierLocationInfo: [],
        });
      });
  };

  const onItemNumberNext = async () => {
    await axios
      .get<ItemData>(Item_GetItemByIdAndSupplierByDirection, {
        params: {
          ItemId: itemData.itemNumber,
          SupplierNumber: supplierData.supplierNumber,
          Direction: "Asc",
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onItemNumberPrevious = async () => {
    await axios
      .get<ItemData>(Item_GetItemByIdAndSupplierByDirection, {
        params: {
          ItemId: itemData.itemNumber,
          SupplierNumber: supplierData.supplierNumber,
          Direction: "Desc",
        },
      })
      .then((response) => {
        setItemData(response.data);
      })
      .catch((error) => {
        setItemData({
          itemNumber: itemData.itemNumber,
          description: "",
        });
      });
  };

  const onSupplierLocationInfoChange = (e: any) => {
    const supplierLocationInfo = supplierData.supplierLocationInfo.find(
      (obj) => {
        return obj.supplierLocationId === e.target.value;
      }
    );
    setSelectedSupplierLocationNumber(
      supplierLocationInfo!.supplierLocationNumber
    );
  };

  const initialiseSupplierLocationNumber = (response: any) => {
    setSelectedSupplierLocationNumber("");
    if (response.data.supplierLocationInfo.length > 0) {
      setSelectedSupplierLocationNumber(
        response.data.supplierLocationInfo[0].supplierLocationNumber
      );
    }
  };

  const resetArtikelData = () => {
    setItemData({
      itemNumber: "",
      description: "",
    });
  };

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-8 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-1 md:mt-0 md:col-span-6">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
              <Modal
                isOn={displayModal}
                onCancel={() => ToggleModal()}
                onSubmit={() => onSubmit("x")}
                title={"Zijn deze gegevens correct?"}
              >
                <div>
                  <div className="grid grid-cols-6 gap-x-6">
                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="Date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Datum
                      </label>
                      <input
                        type="Date"
                        name="Date"
                        id="Date"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.Date}
                        disabled
                      />
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="Time"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tijd
                      </label>
                      <input
                        type="Time"
                        name="Time"
                        id="Time"
                        min="07:00:00"
                        max="18:59:00"
                        key={currentTime}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.Time}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="SupplierName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Leverancier
                      </label>
                      <input
                        type="text"
                        name="SupplierName"
                        id="SupplierName"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.SupplierName}
                        disabled
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="ItemName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Artikel
                      </label>
                      <input
                        type="text"
                        name="ItemName"
                        id="ItemName"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.ItemName}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="Transporter"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Transporteur
                      </label>
                      <input
                        type="text"
                        name="Transporter"
                        id="Transporter"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.Transporter}
                        disabled
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="UnloadingPlace"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Losplaats
                      </label>
                      <input
                        type="text"
                        name="UnloadingPlace"
                        id="UnloadingPlace"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.UnloadingPlace}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3">
                      <label
                        htmlFor="Description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Opmerking
                      </label>
                      <textarea
                        name="Description"
                        id="Description"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.Description}
                        readOnly
                      />
                    </div>

                    <div className="col-span-3">
                      <label
                        htmlFor="Weight"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Gewicht
                      </label>
                      <input
                        type="number"
                        name="Weight"
                        id="Weight"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.Weight}
                        readOnly
                      />
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="VerpakkingGewicht"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verpakking gewicht
                      </label>
                      <input
                        type="number"
                        name="VerpakkingGewicht"
                        id="VerpakkingGewicht"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.VerpakkingGewicht}
                        readOnly
                      />
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="VerpakkingBeschrijving"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verpakking beschrijving
                      </label>
                      <input
                        type="text"
                        name="VerpakkingBeschrijving"
                        id="VerpakkingBeschrijving"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={formData.VerpakkingBeschrijving}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </Modal>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-4 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-x-6">
                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="Date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Datum
                      </label>
                      <input
                        type="Date"
                        name="Date"
                        id="Date"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        defaultValue={new Date().toISOString().substr(0, 10)}
                        required
                      />
                    </div>

                    <div className="col-span-1 sm:col-span-1">
                      <label
                        htmlFor="Time"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tijd
                      </label>
                      <input
                        type="Time"
                        name="Time"
                        id="Time"
                        key={currentTime}
                        min="07:00:00"
                        max="18:59:00"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        defaultValue={currentTime}
                        ref={register}
                        required
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-4" />
                  </div>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="SupplierNr"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Leveranciersnummer
                      </label>
                      <DebounceInput
                        minLength={1}
                        debounceTimeout={300}
                        onChange={(event) =>
                          onSupplierNumberChange(event.target.value)
                        }
                        type="number"
                        name="SupplierNr"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        value={supplierData.supplierNumber}
                        autoComplete="new-password"
                        required
                      />
                      <input
                        type="number"
                        name="SupplierNr"
                        hidden
                        value={supplierData.supplierNumber}
                        ref={register}
                        required
                        readOnly
                      />
                      <i
                        className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                        onClick={() => onSupplierNumberDecrease()}
                      ></i>
                      <i
                        className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                        onClick={() => onSupplierNumberIncrease()}
                      ></i>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="SupplierName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Naam
                      </label>
                      <div className="grid grid-cols-5 gap-x-6">
                        <div className="col-span-4 sm:col-span-5">
                          <DebounceInput
                            key={supplierData.supplierName1}
                            minLength={1}
                            debounceTimeout={1500}
                            onChange={(event) =>
                              onSupplierNameChange(event.target.value)
                            }
                            type="text"
                            name="SupplierName"
                            id="SupplierName"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                            ref={register}
                            value={supplierData.supplierName1}
                            autoComplete="new-password"
                            required
                          />
                          <input
                            type="text"
                            name="SupplierName"
                            hidden
                            value={supplierData.supplierName1}
                            ref={register}
                            readOnly
                            autoComplete="new-password"
                            required
                          />
                          <i
                            className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                            onClick={() => onSupplierNamePrevious()}
                          />
                          <i
                            className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                            onClick={() => onSupplierNameNext()}
                          />
                        </div>
                        <input
                          type="text"
                          name="Supplierstreet"
                          id="SupplierName"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                          value={
                            supplierData.supplierStreet +
                            " " +
                            supplierData.supplierStreetNumber
                          }
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <input
                          type="text"
                          name="SupplierPostalCode"
                          id="SupplierName"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2"
                          value={supplierData.supplierPostalCode}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <input
                          type="text"
                          name="SupplierRegion"
                          id="SupplierName"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-3"
                          value={supplierData.supplierCity}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <div
                          className="hidden sm:block col-span-6 sm:col-span-6 mt-2"
                          aria-hidden="true"
                        >
                          <div>
                            <div className="border-t border-gray-200" />
                          </div>
                        </div>
                        <input
                          type="text"
                          name="SupplierLocationNumber"
                          id="SupplierLocationNumber"
                          className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2"
                          value={selectedSupplierLocationNumber}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                        <select
                          name="SupplierLocationDescription"
                          id="SupplierLocationDescription"
                          className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-3"
                          onChange={(e) => onSupplierLocationInfoChange(e)}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                        >
                          {supplierData.supplierLocationInfo.map(
                            (location, index) => (
                              <option
                                key={index}
                                value={location.supplierLocationId}
                              >
                                {location.supplierLocationDescription}
                              </option>
                            )
                          )}
                        </select>
                        <input
                          type="text"
                          name="supplierVAT"
                          id="supplierVAT"
                          className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-2"
                          value={supplierData.supplierVAT}
                          ref={register}
                          autoComplete="nope"
                          tabIndex={-1}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-5 sm:col-span-5" />

                    <div
                      className="hidden sm:block col-span-6 sm:col-span-6"
                      aria-hidden="true"
                    >
                      <div>
                        <div className="border-t border-gray-200"></div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="Transporter"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Transporteur
                        </label>
                        <div>
                          <input
                            type="checkbox"
                            id="createTransporteurBasedOnLeverancierCheckbox"
                            onChange={() =>
                              setCreateTransporteurBasedOnLeverancier(
                                !createTransporteurBasedOnLeverancier
                              )
                            }
                          ></input>
                          <label htmlFor="createTransporteurBasedOnLeverancierCheckbox">
                            De leverancier zorgt voor transport
                          </label>
                        </div>
                      </div>
                      {TransporteurLijst.length > 0 &&
                      !createTransporteurBasedOnLeverancier ? (
                        <>
                          <Select
                            name="Transporter"
                            ref={register}
                            options={TransporteurLijst.map((transporteur) => ({
                              label: transporteur.naam,
                              value: transporteur.id,
                            }))}
                            defaultValue={{
                              label: TransporteurLijst[0].naam,
                              value: TransporteurLijst[0].id,
                            }}
                            onChange={(opt) =>
                              setGeselecteerdeTransporteur(opt?.label)
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="UnloadingPlace"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Losplaats
                      </label>
                      <input
                        type="text"
                        name="UnloadingPlace"
                        id="UnloadingPlace"
                        defaultValue="Gullegem"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        autoComplete="nope"
                      />
                    </div>

                    <div
                      className="hidden sm:block col-span-6 sm:col-span-6"
                      aria-hidden="true"
                    >
                      <div>
                        <div className="border-t border-gray-200"></div>
                      </div>
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="MaterialId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Artikel nummer
                      </label>
                      <DebounceInput
                        minLength={4}
                        debounceTimeout={250}
                        onChange={(event) =>
                          onMaterialIdChange(event.target.value)
                        }
                        type="text"
                        name="MaterialId"
                        id="MaterialId"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                        ref={register}
                        value={itemData.itemNumber}
                        required
                        autoComplete="nope"
                      />
                      <input
                        type="text"
                        name="MaterialId"
                        hidden
                        value={itemData.itemNumber}
                        ref={register}
                        readOnly
                        required
                        autoComplete="nope"
                      />
                      <i
                        className="ni ni-bold-left text-black py-2 px-4 border hover:bg-gray-300"
                        onClick={() => onItemNumberPrevious()}
                      ></i>
                      <i
                        className="ni ni-bold-right text-black py-2 px-4 border hover:bg-gray-300"
                        onClick={() => onItemNumberNext()}
                      ></i>
                    </div>

                    <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                      <label
                        htmlFor="Material"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Artikel
                      </label>
                      <input
                        type="text"
                        name="Material"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                        value={itemData.description}
                        ref={register}
                        tabIndex={-1}
                        autoComplete="nope"
                        readOnly
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                      <label
                        htmlFor="Description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Opmerking
                      </label>
                      <textarea
                        name="Description"
                        id="Description"
                        onChange={(event) =>
                          onChangeDescription(event.target.value)
                        }
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={description}
                        ref={register}
                        autoComplete="nope"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                      <label
                        htmlFor="Weight"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Gewicht
                      </label>
                      <input
                        type="number"
                        name="Weight"
                        id="Weight"
                        onChange={(event) =>
                          onChangeWeight(Number(event.target.value))
                        }
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        value={weightData.toString()}
                        required
                        autoComplete="nope"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                      <label
                        htmlFor="VerpakkingGewicht"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verpakking gewicht
                      </label>
                      <input
                        type="number"
                        name="VerpakkingGewicht"
                        id="VerpakkingGewicht"
                        onChange={(event) =>
                          onChangeVerpakkingGewicht(Number(event.target.value))
                        }
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        value={verpakkingGewicht.toString()}
                        autoComplete="nope"
                      />
                    </div>

                    <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                      <label
                        htmlFor="VerpakkingBeschrijving"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verpakking beschrijving
                      </label>
                      <input
                        type="text"
                        name="VerpakkingBeschrijving"
                        id="VerpakkingBeschrijving"
                        onChange={(event) =>
                          onChangeVerpakkingBeschrijving(event.target.value)
                        }
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-6 sm:col-span-5"
                        value={verpakkingBeschrijving}
                        ref={register}
                        autoComplete="nope"
                      />
                    </div>
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <input
                    type="submit"
                    value="Opslaan"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default GewichtsNota;
