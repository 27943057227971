import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { AppStore } from "../../../Store/AppState";
import { Auth_Login} from "../../../ApiEndpoints";
import { parseJwt } from "../../../Utils/UtilFunctions.js";
import { useHistory } from "react-router-dom";

function Login() {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const history = useHistory();  
  const jwtToken = AppStore.useState(s => s.jwtToken);
  const jwtExpiration = AppStore.useState(s => s.jwtExpiration);

  const loginForm = () => {
    axios.post(Auth_Login,
      {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        username:email,
        password:password,
      }).then((response) =>{
        
        let jwtJson = parseJwt(response.data.token);

        localStorage.setItem("jwt", response.data.token);

        AppStore.update(s => {
          s.jwtToken = response.data.token;
          s.jwtExpiration = jwtJson.exp;
          s.jwtEmail = jwtJson["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
          s.jwtName = jwtJson["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
          s.jwtRole = jwtJson["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        })
        history.push("/")
      })
      .catch((error)=>{
      })
  }

  const linkToRequestForgotPassword = () => {
    history.push("/auth/RequestPasswordReset")
  }

  const checkIfJwtInLocalStorage = () => {
    const jwt = localStorage.getItem("jwt");
    if(jwt){
      const jwtJson = parseJwt(jwt);

      AppStore.update(s => {
        s.jwtToken = jwt;
        s.jwtExpiration = jwtJson.exp;
        s.jwtEmail = jwtJson["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        s.jwtName = jwtJson["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        s.jwtRole = jwtJson["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      })
    }
  }

  const checkIfLoggedIn = () => {
    if((jwtToken !== "" || jwtToken !== undefined ) && ( jwtExpiration !== "" || Date.now() <= new Date(jwtExpiration).getTime() ))
      history.push("/")
  }

  const onKeyDownEnter = (e) => {
    if(e.keyCode === 13){
      loginForm();
    }
  }

  //Execute functions
  checkIfJwtInLocalStorage()
  checkIfLoggedIn()

  return (
    <>
      <Container className="mt-10 sm:mt-10 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
            <div className="text-xl text-center mt-5 mb-3">
                  <small>Sign in</small>
                </div>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onKeyDown={(e)=>onKeyDownEnter(e)}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={(e) => {setEmail(e.target.value)}}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={(e) => {setPassword(e.target.value)}}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" onClick={() => loginForm()}>
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  onClick={() => linkToRequestForgotPassword()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
