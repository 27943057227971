import axios from "axios";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import {
  Supplier_EnvironmentalRegister,
  Weight_Weightnotes_DocumentToEmail,
  Supplier_MatisEnvironmentalRegister_Verwerker,
  Supplier_MatisEnvironmentalRegister_Ihm,
  Documents_GetPricelist,
  Documents_GetUitgaandeAfvalstoffenLijst,
  Documents_GetGoedgekeurdeArtikelsPerLeverancierLijst
} from "../../../ApiEndpoints";

const Lijsten: React.FC = () => {
  const [weightlistDate, setWeightlistDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [environmentFromDate, setEnvironmentFromDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [environmentToDate, setEnvironmentToDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [matisVerwerkerFromDate, setMatisVerwerkerFromDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [matisVerwerkerToDate, setMatisVerwerkerToDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [priceListFromDate, setPriceListFromDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [priceListToDate, setPriceListToDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [
    uitgaandeAfvalstoffenLijstFromDate,
    setUitgaandeAfvalstoffenLijstFromDate,
  ] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [
    uitgaandeAfvalstoffenLijstToDate,
    setUitgaandeAfvalstoffenLijstToDate,
  ] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [matisIhmFromDate, setMatisIhmFromDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const [matisIhmToDate, setMatisIhmToDate] = useState<string>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
      .toISOString()
      .substr(0, 10)
  );
  const CreateWeightNoteListAndSendMail = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Gewichtslijsten aan het maken en Mails aan het versturen...",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Weight_Weightnotes_DocumentToEmail, {
        params: {
          date: weightlistDate,
        },
      })
      .then((response) => {
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };

  const CreateEnvironmentList = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Gewichtslijsten aan het maken en Mails aan het versturen...",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Supplier_EnvironmentalRegister, {
        params: {
          From: environmentFromDate,
          To: environmentToDate,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Afvalstoffen register_" + environmentFromDate + ".csv"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };

  const CreateMatisVerwerkerList = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Matislijsten Verwerker aan het genereren",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Supplier_MatisEnvironmentalRegister_Verwerker, {
        params: {
          From: matisVerwerkerFromDate,
          To: matisVerwerkerToDate,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Matis_Verwerker_" + matisVerwerkerFromDate + ".csv"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };

  const CreatePriceList = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Prijs lijst aan het genereren",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Documents_GetPricelist, {
        params: {
          BeginDate: priceListFromDate,
          EndDate: priceListToDate,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/zip",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "prijslijsten_" + priceListFromDate + "-" + priceListToDate + ".zip"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };

  const CreateUitgaandeAfvalstoffenLijst = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Uitgaande afvalstoffen lijst aan het genereren",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Documents_GetUitgaandeAfvalstoffenLijst, {
        params: {
          BeginDate: uitgaandeAfvalstoffenLijstFromDate,
          EndDate: uitgaandeAfvalstoffenLijstToDate,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "text/csv",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Geproduceerde Afvalstoffen register_" +
            uitgaandeAfvalstoffenLijstFromDate +
            "-" +
            uitgaandeAfvalstoffenLijstToDate +
            ".csv"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };

  const CreateMatisIhmList = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Matis lijst Ihm aan het genereren",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Supplier_MatisEnvironmentalRegister_Ihm, {
        params: {
          From: matisIhmFromDate,
          To: matisIhmToDate,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Matis_Ihm_" + matisIhmFromDate + ".csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };

  const CreateGoedgekeurdeArtikelsperLeverancierlijst = () => {
    let notifID = store.addNotification({
      title: "Loading",
      message: "Goedgekeurde artikels per leverancier lijst aan het genereren",
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
    });

    axios
      .get(Documents_GetGoedgekeurdeArtikelsPerLeverancierLijst, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GoedgekeurdeArtikelsperLeverancierlijst.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        store.removeNotification(notifID);
      })
      .catch((error) => {
        store.removeNotification(notifID);
      });
  };
  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-1" />
          <div className="mt-1 md:mt-0 col-span-10">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-4 bg-white sm:p-6">
                {/* Card */}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-upload-96 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                          Gewichtslijst mails versturen
                        </p>
                        <label
                          htmlFor="WeightlistFrom"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Van
                        </label>
                        <input
                          type="date"
                          name="WeightlistFrom"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setWeightlistDate(ev.target.value)}
                          defaultValue={weightlistDate}
                        />
                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreateWeightNoteListAndSendMail();
                          }}
                        >
                          Verzenden
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-download-95 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                          Prijslijst Genereren
                        </p>
                        <label
                          htmlFor="PriceListForm"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Van
                        </label>
                        <input
                          type="date"
                          name="PriceListForm"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setPriceListFromDate(ev.target.value)}
                          defaultValue={priceListFromDate}
                        />
                        <label
                          htmlFor="PricelistTo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tot
                        </label>
                        <input
                          type="date"
                          name="PricelistTo"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setPriceListToDate(ev.target.value)}
                          defaultValue={priceListToDate}
                        />
                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreatePriceList();
                          }}
                        >
                          {" "}
                          genereren
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-download-95 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                          Afvalstoffen Register genereren
                        </p>
                        <label
                          htmlFor="EnvironmentFrom"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Van
                        </label>
                        <input
                          type="date"
                          name="EnvironmentFrom"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setEnvironmentFromDate(ev.target.value)}
                          defaultValue={environmentFromDate}
                        />
                        <label
                          htmlFor="EnvironmentTo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tot
                        </label>
                        <input
                          type="date"
                          name="EnvironmentTo"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setEnvironmentToDate(ev.target.value)}
                          defaultValue={environmentToDate}
                        />
                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreateEnvironmentList();
                          }}
                        >
                          {" "}
                          genereren
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-download-95 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                          Geproduceerde afvalstoffen Register Genereren
                        </p>
                        <label
                          htmlFor="UitgaandeAfvalstoffenLijstForm"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Van
                        </label>
                        <input
                          type="date"
                          name="UitgaandeAfvalstoffenLijstForm"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            setUitgaandeAfvalstoffenLijstFromDate(
                              ev.target.value
                            )
                          }
                          defaultValue={uitgaandeAfvalstoffenLijstFromDate}
                        />
                        <label
                          htmlFor="UitgaandeAfvalstoffenLijstTo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tot
                        </label>
                        <input
                          type="date"
                          name="UitgaandeAfvalstoffenLijstTo"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            setUitgaandeAfvalstoffenLijstToDate(ev.target.value)
                          }
                          defaultValue={uitgaandeAfvalstoffenLijstToDate}
                        />
                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreateUitgaandeAfvalstoffenLijst();
                          }}
                        >
                          {" "}
                          genereren
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-download-95 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                          Matis lijst Verwerker genereren
                        </p>
                        <label
                          htmlFor="EnvironmentFrom"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Van
                        </label>
                        <input
                          type="date"
                          name="EnvironmentFrom"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setMatisVerwerkerFromDate(ev.target.value)}
                          defaultValue={matisVerwerkerFromDate}
                        />
                        <label
                          htmlFor="EnvironmentTo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tot
                        </label>
                        <input
                          type="date"
                          name="EnvironmentTo"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setMatisVerwerkerToDate(ev.target.value)}
                          defaultValue={matisVerwerkerToDate}
                        />
                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreateMatisVerwerkerList();
                          }}
                        >
                          {" "}
                          genereren
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-download-95 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                          Matis lijst Ihm genereren
                        </p>
                        <label
                          htmlFor="EnvironmentFrom"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Van
                        </label>
                        <input
                          type="date"
                          name="EnvironmentFrom"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setMatisIhmFromDate(ev.target.value)}
                          defaultValue={matisIhmFromDate}
                        />
                        <label
                          htmlFor="EnvironmentTo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tot
                        </label>
                        <input
                          type="date"
                          name="EnvironmentTo"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ): void => setMatisIhmToDate(ev.target.value)}
                          defaultValue={matisIhmToDate}
                        />
                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreateMatisIhmList();
                          }}
                        >
                          {" "}
                          genereren
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div className="px-6 py-4 flex justify-center flex-col">
                        <i
                          className="px-10 ni ni-cloud-download-95 text-grey font-bold mb-2"
                          style={{ fontSize: "10rem" }}
                        />
                        <p className="inline-flex justify-center text-gray-700 text-base font-bold">
                        Goedgekeurde artikels per leverancier lijst genereren
                        </p>

                        <button
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            CreateGoedgekeurdeArtikelsperLeverancierlijst();
                          }}
                        >
                          {" "}
                          genereren
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1" />
        </div>
      </div>
    </>
  );
};

export default Lijsten;
