import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm, NestedValue } from "react-hook-form";
import { store } from "react-notifications-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal/modal";
import {
  Item_Create,
  Item_GetItemById,
  Item_List,
  Item_Update,
} from "../../../ApiEndpoints";

export interface Item {
  id: string;
  itemNumber: string;
  description: string;
  euralCode: string;
}

export interface FullItem {
  id: string;
  itemNumber: string;
  itemCode: string;
  buySell: string;
  description: string;
  descriptionDutch: string;
  descriptionFrench: string;
  descriptionEnglish: string;
  descriptionGerman: string;
  euralCode: string;
  rdCode: string;
  processingType: string;
  processingTypeDescription: string;
}

const CreateItem: React.FC = () => {
  const [itemList, setItemList] = useState<Array<Item>>([]);
  const [isOn, setIsOn] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<FullItem>({
    id: "test",
    itemNumber: "55555",
    itemCode: "",
    buySell: "",
    description: "",
    descriptionDutch: "",
    descriptionFrench: "",
    descriptionEnglish: "",
    descriptionGerman: "",
    euralCode: "",
    rdCode: "",
    processingType: "",
    processingTypeDescription: "",
  });

  const { register, handleSubmit } = useForm<{
    select: NestedValue<number[]>;
  }>({
    defaultValues: { select: [] },
  });

  useEffect(() => {
    RetrieveItemList();
  }, []);

  const onSubmit = (data: any) => {
    if (itemList.some((item) => item.itemNumber === data.ItemNumber)) {
      store.addNotification({
        title: "Artikel nummer bestaat al!",
        message: "artikel nummer " + data.ItemNumber + " wordt al gebruikt!",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      return;
    }

    axios
      .post(Item_Create, {
        ItemNumber: data.ItemNumber,
        ItemCode: data.ItemCode,
        BuySell: data.BuySell,
        Description: data.Description,
        DescriptionDutch: data.DescriptionDutch,
        DescriptionFrench: data.DescriptionFrench,
        DescriptionEnglish: data.DescriptionEnglish,
        DescriptionGerman: data.DescriptionGerman,
        EuralCode: data.EuralCode,
        RDCode: data.RDCode,
        ProcessingType: data.ProcessingType,
        ProcessingTypeDescription: data.ProcessingTypeDescription,
      })
      .then((response) => {
        ClearFields();
        window.location.reload();
      })
      .catch((error) => {});
  };

  const RetrieveItemList = async () => {
    axios
      .get(Item_List)
      .then((response) => {
        setItemList(response.data.result);
      })
      .catch((error) => {});
  };

  const ClearFields = async () => {};

  const onPressEdit = async (itemNumber: string) => {
    axios
      .get(Item_GetItemById, {
        params: {
          ItemNumber: itemNumber,
        },
      })
      .then((response) => {
        setEditableItem(response.data);
        setIsOn(true);
      })
      .catch((error) => {});
  };

  const onPressCancel = () => {
    setIsOn(false);
  };

  const onPressSubmit = () => {
    axios
      .post(Item_Update, {
        Id: editableItem.id,
        ItemNumber: editableItem.itemNumber,
        ItemCode: editableItem.itemCode,
        BuySell: editableItem.buySell,
        Description: editableItem.description,
        DescriptionDutch: editableItem.descriptionDutch,
        DescriptionFrench: editableItem.descriptionFrench,
        DescriptionEnglish: editableItem.descriptionEnglish,
        DescriptionGerman: editableItem.descriptionGerman,
        EuralCode: editableItem.euralCode,
        RDCode: editableItem.rdCode,
        ProcessingType: editableItem.processingType,
        ProcessingTypeDescription: editableItem.processingTypeDescription,
      })
      .then((response) => {
        setIsOn(false);
        window.location.reload();
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="mt-10 sm:mt-0">
        {editableItem.id !== "test" ? (
          <Modal
            key={editableItem.id}
            isOn={isOn}
            onCancel={onPressCancel}
            onSubmit={onPressSubmit}
            title={"Artikel aanpassen"}
          >
            <div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="ModalItemNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Artikel nummer
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        itemNumber: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalItemNumber"
                    id="ModalItemNumber"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    autoComplete="nope"
                    defaultValue={editableItem.itemNumber}
                  />
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="ModalItemCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Goederen code
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        itemCode: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalItemCode"
                    id="ModalItemCode"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.itemCode}
                  />
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="ModalBuySell"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Aan/Verkoop (A/V)
                  </label>
                  <select
                    onBlur={(ev: React.ChangeEvent<HTMLSelectElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        buySell: ev.target.value,
                      })
                    }
                    name="ModalBuySell"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    ref={register}
                    defaultValue={editableItem.buySell}
                    autoComplete="nope"
                  >
                    <option value="A">Aankoop</option>
                    <option value="V">Verkoop</option>
                  </select>
                </div>

                <div className="col-span-4 sm:col-span-4" />
              </div>
              <br />
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="ModalDescription"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Omschrijving
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        description: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalDescription"
                    id="ModalDescription"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.description}
                  />
                  <label
                    htmlFor="ModalDescriptionDutch"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Omschrijving nederlands
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        descriptionDutch: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalDescriptionDutch"
                    id="ModalDescriptionDutch"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.descriptionDutch}
                  />
                  <label
                    htmlFor="ModalEuralCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Eural Code
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        euralCode: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalEuralCode"
                    id="ModalEuralCode"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.euralCode}
                  />
                  <label
                    htmlFor="ModalRDCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    R/D Code
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        rdCode: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalRDCode"
                    id="ModalRDCode"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.rdCode}
                  />
                </div>

                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="ModalDescriptionFrench"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Omschrijving Frans
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        descriptionFrench: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalDescriptionFrench"
                    id="ModalDescriptionFrench"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.descriptionFrench}
                  />
                  <label
                    htmlFor="ModalDescriptionEnglish"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Omschrijving Engels
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        descriptionEnglish: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalDescriptionEnglish"
                    id="ModalDescriptionEnglish"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.descriptionEnglish}
                  />
                  <label
                    htmlFor="ModalDescriptionGerman"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Omschrijving Duits
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        descriptionGerman: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalDescriptionGerman"
                    id="ModalDescriptionGerman"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.descriptionGerman}
                  />
                  <label
                    htmlFor="ModalProcessingType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Verwerkingswijze
                  </label>
                  <select
                    onBlur={(ev: React.ChangeEvent<HTMLSelectElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        processingType: ev.target.value,
                      })
                    }
                    name="ModalProcessingType"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    defaultValue={editableItem.processingType}
                    autoComplete="nope"
                  >
                    <option value="Storten">Storten</option>
                    <option value="Verbranden Met Energierecuperatie">
                      Verbranden met energierecuperatie
                    </option>
                    <option value="Andere Afvalverbranding">
                      Andere afvalverbranding
                    </option>
                    <option value="Hergebruik">Hergebruik</option>
                    <option value="Composteren/Vergisten">
                      Composteren/Vergisten
                    </option>
                    <option value="Recyclage" selected>
                      Recyclage
                    </option>
                    <option value="Sorteren">Sorteren</option>
                    <option value="Andere Voorbehandeling">
                      Andere voorbehandeling
                    </option>
                    <option value="Op- En Overslag">Op-en overslag</option>
                  </select>
                  <label
                    htmlFor="ModalProcessingTypeDescription"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Verwerkingswijze omschrijving
                  </label>
                  <input
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setEditableItem({
                        ...editableItem,
                        processingTypeDescription: ev.target.value,
                      })
                    }
                    type="text"
                    name="ModalProcessingTypeDescription"
                    id="ModalProcessingTypeDescription"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                    autoComplete="nope"
                    defaultValue={editableItem.processingTypeDescription}
                  />
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          <></>
        )}
        <div className="md:grid md:grid-cols-12 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-10">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope">
              <div className="shadow overflow-hidden sm:rounded-md flex">
                <div className="px-4 py-5 bg-white sm:p-6 w-4/7">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="ItemNumber"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Artikel nummer
                      </label>
                      <input
                        type="text"
                        name="ItemNumber"
                        id="ItemNumber"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        ref={register}
                        autoComplete="nope"
                        required
                      />
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="ItemCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Goederen code
                      </label>
                      <input
                        type="text"
                        name="ItemCode"
                        id="ItemCode"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label
                        htmlFor="BuySell"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Aan/Verkoop
                      </label>
                      <select
                        name="BuySell"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        ref={register}
                        required
                        defaultValue="V"
                        autoComplete="nope"
                      >
                        <option value="A">Aankoop</option>
                        <option value="V">Verkoop</option>
                      </select>
                    </div>

                    <div className="col-span-4 sm:col-span-4" />
                  </div>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="Description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Omschrijving
                      </label>
                      <input
                        type="text"
                        name="Description"
                        id="Description"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                        required
                      />
                      <label
                        htmlFor="DescriptionDutch"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Omschrijving nederlands
                      </label>
                      <input
                        type="text"
                        name="DescriptionDutch"
                        id="DescriptionDutch"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                      <label
                        htmlFor="EuralCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Eural Code
                      </label>
                      <input
                        type="text"
                        name="EuralCode"
                        id="EuralCode"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                        required
                      />
                      <label
                        htmlFor="RDCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        R/D Code
                      </label>
                      <input
                        type="text"
                        name="RDCode"
                        id="RDCode"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                        required
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label
                        htmlFor="DescriptionFrench"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Omschrijving Frans
                      </label>
                      <input
                        type="text"
                        name="DescriptionFrench"
                        id="DescriptionFrench"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                      <label
                        htmlFor="DescriptionEnglish"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Omschrijving Engels
                      </label>
                      <input
                        type="text"
                        name="DescriptionEnglish"
                        id="DescriptionEnglish"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                      <label
                        htmlFor="DescriptionGerman"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Omschrijving Duits
                      </label>
                      <input
                        type="text"
                        name="DescriptionGerman"
                        id="DescriptionGerman"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                      <label
                        htmlFor="ProcessingType"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verwerkingswijze
                      </label>
                      <select
                        name="ProcessingType"
                        id="ProcessingType"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      >
                        <option value="Storten">Storten</option>
                        <option value="Verbranden Met Energierecuperatie">
                          Verbranden met energierecuperatie
                        </option>
                        <option value="Andere Afvalverbranding">
                          Andere afvalverbranding
                        </option>
                        <option value="Hergebruik">Hergebruik</option>
                        <option value="Composteren/Vergisten">
                          Composteren/Vergisten
                        </option>
                        <option value="Recyclage" selected>
                          Recyclage
                        </option>
                        <option value="Sorteren">Sorteren</option>
                        <option value="Andere Voorbehandeling">
                          Andere voorbehandeling
                        </option>
                        <option value="Op- En Overslag">Op- en overslag</option>
                      </select>
                      <label
                        htmlFor="ProcessingTypeDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verwerkingswijze omschrijving
                      </label>
                      <input
                        type="text"
                        name="ProcessingTypeDescription"
                        id="ProcessingTypeDescription"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md col-span-4 sm:col-span-5"
                        ref={register}
                        autoComplete="nope"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-5 bg-white sm:p-6 w-3/7 ">
                  <table className="table-fixed text-left">
                    <thead className="flex w-full">
                      <tr className="flex w-full">
                        <th className="w-1/12 px-4 py-2"></th>
                        <th className="w-2/12 px-4 py-2">Artikel nummer</th>
                        <th className="w-6/12 px-4 py-2">
                          Artikel Omschrijving
                        </th>
                        <th className="w-2/12 px-4 py-2">Eural code</th>
                      </tr>
                    </thead>
                    <tbody
                      className="bg-grey-light flex flex-col items-center justify-between overflow-y-scroll w-full"
                      style={{ height: "50vh" }}
                    >
                      {itemList.map((item) => (
                        <tr className="flex w-full" key={item.id}>
                          <td className="px-4 py-2 text-light-blue-600 font-medium w-1/12">
                            <FontAwesomeIcon
                              icon={faPen}
                              onClick={() => {
                                onPressEdit(item.itemNumber);
                              }}
                            />
                          </td>
                          <td className="border-r-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-2/12">
                            {item.itemNumber}
                          </td>
                          <td className=" px-4 py-2 text-light-blue-600 font-medium w-6/12">
                            {item.description}
                          </td>
                          <td className="border-l-2 border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium w-2/12">
                            {item.euralCode}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <input
                  type="submit"
                  value="Save"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                />
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default CreateItem;
